<template>
    <header class="br__header">
        <div class="topbar">
            <ul class="links">
                <li> <a href="#">Explore more at Duracell.co.uk</a> </li>
                <li> <a href="#">Help Center</a> </li>
                <li> <a href="#">Q & A</a> </li>
                <ul class="right">
                    <li v-if="!currentUser"> <a href="#">Log in / Register</a> </li>
                    <li v-if="currentUser"> <a href="#" @click="logOutBtnClick">{{capitalize(currentUser.first_name)}} {{capitalize(currentUser.last_name)}}, Logout</a> </li>
                    <li>
                        <router-link :to="{name: 'Cart'}" class="nav__cart">
                            <div id="nav-cart-count-container" class="cart__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.811" height="19.54" viewBox="0 0 20.811 19.54">
                                    <g id="cart" transform="translate(0.25 0.25)">
                                        <g id="Group_24" data-name="Group 24" transform="translate(6.347 15.232)">
                                        <g id="Group_23" data-name="Group 23">
                                            <path id="Path_64" data-name="Path 64" d="M161.9,399.988a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,161.9,399.988Zm0,2.539a.635.635,0,1,1,.635-.635A.635.635,0,0,1,161.9,402.527Z" transform="translate(-160 -399.988)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                                        </g>
                                        </g>
                                        <g id="Group_26" data-name="Group 26" transform="translate(13.963 15.232)">
                                        <g id="Group_25" data-name="Group 25">
                                            <path id="Path_65" data-name="Path 65" d="M353.9,399.988a1.9,1.9,0,1,0,1.9,1.9A1.906,1.906,0,0,0,353.9,399.988Zm0,2.539a.635.635,0,1,1,.635-.635A.635.635,0,0,1,353.9,402.527Z" transform="translate(-352 -399.988)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                                        </g>
                                        </g>
                                        <g id="Group_28" data-name="Group 28" transform="translate(0 0)">
                                        <g id="Group_27" data-name="Group 27">
                                            <path id="Path_66" data-name="Path 66" d="M20.161,18.755a.631.631,0,0,0-.486-.228H4.758l-.552-2.068a.633.633,0,0,0-.613-.471H.635a.635.635,0,1,0,0,1.269H3.1L6.368,29.48a.633.633,0,0,0,.613.471H17.136a.635.635,0,0,0,0-1.269H7.469L7.1,27.288l11.337-.51a.634.634,0,0,0,.6-.52L20.3,19.276A.633.633,0,0,0,20.161,18.755Zm-2.289,6.777-11.112.5L5.1,19.8H18.915Z" transform="translate(0 -15.988)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <strong class="show-desktop">{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{$store.getters.totalCartValue}}</strong>
                            <!-- <strong class="show-desktop">0.00€ {{$store.getters.cartItems.length}}</strong> -->
                        </router-link>
                    </li>
                </ul>
            </ul>
        </div>
        <div class="logo_line">
            <div class="logo">
                <router-link
                        to="/"
                    ><img :src="logoLoad" alt="">
                </router-link>
            </div>
            <a href="#">Search for a list of items</a>
        </div>
        <div class="category-list" v-if="true">
            <nav class="menulist new" v-if="categories && getValueFromObj(initialCaratSetting, 'iEFavourite')">
                <slick ref="slickHead" class="menulist-strip" :options="slickOptions" v-if="categories.length">
                    <a v-for="(item, i) in categories" :key="i" @dblclick="gotoSearchDouble(item)"  @click="gotoSearchDouble(item)" :class="item.id == $store.state.selectedCategory.id ? 'active': ''" >{{item.name}}</a>
                </slick>
            </nav>
            <nav class="menulist" v-if="categories && !getValueFromObj(initialCaratSetting, 'iEFavourite')"> 
                <slick ref="slickHead1" class="menulist-strip" :options="slickOptionsN" v-if="cats.length">
                    <a v-for="(item, i) in cats" :key="i" @dblclick="gotoSearchDouble(item)"  @click="gotoSearchDouble(item)" :class="item.id == $store.state.selectedCategory.id ? 'active': ''" >{{item.name}}</a>
                </slick>
            </nav>
            <div class="search-field" >
                <form accept-charset="utf-8" @submit.prevent="searchFormSubmit" class="nav-searchbar" method="GET" name="site-search" role="search">
                    <input type="text" name="search" class="nav-input" v-model="search" placeholder="Search product">
                    <button type="submit" class="nav-input" tabindex="0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.246" height="17.252" viewBox="0 0 15.246 17.252">
                            <g id="Icon_feather-search" data-name="Icon feather-search" transform="matrix(0.966, 0.259, -0.259, 0.966, 3.575, 0.612)">
                                <path id="Path_41" data-name="Path 41" d="M11.448,5.724A5.724,5.724,0,1,1,5.724,0a5.724,5.724,0,0,1,5.724,5.724Z" transform="translate(0 0)" fill="none" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                <path id="Path_42" data-name="Path 42" d="M3.112,3.112,0,0" transform="translate(9.767 9.767)" fill="none" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                        </svg>

                    </button>
                </form>
            </div>
        </div>
        <section class="brandstore_banner" v-if="$route.name == 'BrandStore'" :style="{backgroundSize: 'cover', backgroundImage: `url(${require('../assets/brandstore_banner.png')})`}">
        </section>
        <nav class="sub-menu-product" v-if="($route.name == 'ProductDetail' || $route.name == 'BrandStore') && getValueFromObj(initialCaratSetting, 'iEBrandMenu')">
            <div class="menulist-strip">
                <h3 class="heading">Duracell products</h3>
                <router-link :to="`/brand-store/Alkaline batteries`" :class="checkActive('Alkaline batteries')"><span><img src="../assets/cell1.png" alt=""></span> Alkaline batteries</router-link>
                <router-link :to="`/brand-store/Duracell Optimum`" :class="checkActive('Duracell Optimum')"><span><img src="../assets/cell2.png" alt=""></span> Duracell Optimum</router-link>
                <router-link :to="`/brand-store/Specialties & Coin batteries`" :class="checkActive('Specialties & Coin batteries')"><span><img src="../assets/cell3.png" alt=""></span> Specialties & Coin batteries</router-link>
                <router-link :to="`/brand-store/Power banks`" :class="checkActive('Power banks')"><span><img src="../assets/cell4.png" alt=""></span> Power banks</router-link>
                <router-link :to="`/brand-store/Rechargeable & specialty`" :class="checkActive('Rechargeable & specialty')"><span><img src="../assets/cell5.png" alt=""></span> Rechargeable & specialty</router-link>
            </div>
        </nav>
    </header>
</template>

<script>
import Slick from "vue-slick";
import { authComputed, authMethods } from '../store/helpers'
import { find } from 'lodash'
export default {
  name: '',
  components: { 'slick': Slick, },
  computed: {
      ...authComputed,
      cats(){
        let newArr = [...this.categories];
        newArr.splice(1,1)
        return newArr   
      },
      logoLoad() {
          return this.$store.state.initialCaratSettingValue.selectedStore.logo ?
            this.$store.state.initialCaratSettingValue.selectedStore.logo : this.$store.state.genralInfo.logo
      }
  },
  data() {
    return { 
        name: '',
        selectedCategory: 'null',
        search: '',
        selectedCategoryName: 'All',
        slickOptions: {
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true,
        },
        slickOptionsN: {
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true,
        },
    }
  },
  mounted() {
    this.submitFrontForm();
  },
  watch: {
    selectedCategory() {
        this.selectedCategoryName = find(this.categories, {"id": this.selectedCategory}).name
        this.$store.commit("SET_SELECTED_CATEGORY", find(this.categories, {"id": this.selectedCategory}))
    },
    categories() {
        this.selectedCategoryName = this.categories[0].name
        this.$store.commit("SET_SELECTED_CATEGORY", this.categories[0])
    }
  },
  methods: {
      ...authMethods,
      searchFormSubmit(e) {
          e.preventDefault();
          this.getKeywordBanner(this.search);
          this.$router.push({ path: '/search', query: { category: this.$store.state.selectedCategory.id, search: this.search } })
      },
      checkActive(name) {
          const {id} = this.$route.params;
          if(name == id) {
              return 'active'
          }
      },
      gotoSearch(item) {
        this.$store.commit("SET_SELECTED_CATEGORY", item);
        if(item.slug == "favourite") {
            this.$router.push({ path: '/favourite' });
        }
      },
      gotoSearchDouble(item) {
        if(item.slug == "favourite") {
            this.$router.push({ path: '/favourite' });
        } else {
            this.$router.push({ path: '/search', query: { category: item.id } });
        }
        this.$store.commit("SET_SELECTED_CATEGORY", item);
      },
      logOutBtnClick() {
          this.logOut().then(() => {
              this.$router.push({ name: 'Login' });
              window.localStorage.removeItem("vuex")
              location.reload();
          })
      }
  }
}
</script>

<style scoped lang="scss">

</style>
