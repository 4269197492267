<template>
  <section class="insightspanel" ref="insightspanel">
    <div class="close_btn" v-on:click="closeInsights"><img src="../assets/modal_close.svg" alt=""></div>
    <h3 class="title">
        <img src="../assets/infoicon.svg" alt="">
        Hotpost / Insight panel
        
    </h3>
    <div class="insights">
        <div class="insights_list">
            <h4 class="subtitle"> {{ $store.state.insights.particularInsight['title']}}</h4>
            <h5 class="tag">{{ $store.state.insights.particularInsight['sell_out_text']}}</h5>
            <p class="desc" v-html="$store.state.insights.particularInsight['description']">
                
            </p>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    closeInsights() {
      document.getElementsByClassName("insightspanel")[0].style.right = "-100%";
    }
  }
}
</script>

<style>

</style>