import axios from 'axios'

export const carats = {
  state: () => ({
    isOpenTab: {
      main: true,
      search: false,
      assorment: false,
      content: false,
      rate: false,
      activeXSelling: false,
      compenstiation: false,
      customerDesign: false,
    },

    eSellOutToday: 1000000,
    growthIndex: '170-180',
    sellOutForcast: '$1.980.000',
    increRevenues: '+$980.000',
    peProg: 10,
    iEHotspot: true,
    iEStore: false,

    iERescentlyPurchase: false,
    iEFavourite: true,
    iEHighlight: false,
    iERetailer: false,
    iEPremium: false,
    numberOfImages: 5,
    numberOfBullets: 7,
    // productVariationStyle: 'Size & Style + Thumbnails',
    productVariationStyle: 'Size & Style + Thumbnails',
    compenstiation: [],
    customerDesign: [],
    rdBanner:"",
    rdRate:"",
    rdSecondaryImages:"",
    incremental_per_section: {
      active_x_selling: 0,
      assortment: 0,
      content: 0,
      rates_reviews: 0,
      search: 0
    },
    prevState: {
      iEHotspot: true,
      iEStore: false,

      iESponsoredProduct: false,
      iERescentlyPurchase: false,
      iEBanner: false,
      iERecords: false,
      iEVideoSearch: false,
      
      iEEfficient: false,
      iEHighlight: false,
      iERetailer: false,
      iEMobileFirst: false,
      iEOptimized: false,
      iESecondaryImages: false,
      numberOfImages: 5,
      iEVideo: false,
      iEBulletPoints: false,
      numberOfBullets: 7,
      iEProductDesc: false,
      iERichContent: false,
      iEProductVariation: false,
      productVariationStyle: 'Size & Style + Thumbnails',
      iEBrandMenu: false,
      iERate: false,
      iELightBox: false,
      iEFrequentlyBought: false,
      iEPremium: false,
      iEVirtual: false,
      iECheckout: false,
    }
  }),
  mutations: {
    SET_CARATS(state, payload) {
      state[payload.forKey] = payload.value;
    },
    SET_INITIAL_USER_SETTINGS(state, payload) {
      state.intialUserSettings = payload;
    },
    SET_PREV_STATE(state, payload) {
      state.prevState[payload.forKey] = payload.value;
    },
    SET_CARATSOPENCLOSE(state, payload) {
      // var key = loDash.findKey(state, 'peProg')
      // console.log(payload, state[key], typeof('growthIndex'));
      // console.log(state, forKey, loDash.findKey(state, forKey));
      state.isOpenTab[payload.forKey] = payload.value;
    },
  },
  actions: {
    setCarats({ commit }, value) {
      commit("SET_CARATS", value);
    },
    setPrevState({ commit }, value) {
      commit("SET_PREV_STATE", value);
      // for (const [key, value] of Object.entries(a)) {
      //   commit("SET_PREV_STATE", {forKey: key, value: value});
      // }
    },
    setCaratsOpen({ commit }, value) {
      commit("SET_CARATSOPENCLOSE", value);
    },
    getCaratsValue({ commit }) {
      return axios
      .get('/list_carat_settings?token=true')
      .then((res) => {
        commit("SET_INITIAL_USER_SETTINGS", res.data.data);
        return res.data.data
      })
      // commit("SET_CARATSOPENCLOSE", value);
      // https://staging.duracell-perfect-store.com/api/list_carat_settings
    },
    submitFrontForm({ commit }) {
      let arr = this.state.initialCaratSettingValue.settings;
      let expectedValue = {};
      for (let i=0; i < arr.length ; ++i) {
        expectedValue[arr[i].key] = arr[i].toggle_value == null ? false : (arr[i].toggle_value == "1" ? true : false);
      }
      var abc = { ...this.state.carats, ...expectedValue };
      delete abc.isOpenTab;
      delete abc.eSellOutToday;
      delete abc.growthIndex;
      delete abc.sellOutForcast;
      delete abc.increRevenues;
      delete abc.prevState;
      var a = {
        user_settings: {
          ...this.state.caratshome,
          eSellOutToday: this.state.carats.eSellOutToday,
        },
        carats: {
          ...abc,
        },
      };
      console.log('M-A: ' + axios.baseCalcURL)
      return axios
      .post(axios.baseCalcURL + '/calc/carats', a)
      .then((res) => {
        var b = res.data;

        commit("SET_CARATS", { forKey: "growthIndex", value: b.growth_index.lower + ' - ' + b.growth_index.upper });
        commit("SET_CARATS", { forKey: "sellOutForcast", value: b.e_sell_out_forecast });
        commit("SET_CARATS", { forKey: "increRevenues", value: b.incremental_revenues });
        commit("SET_CARATS", { forKey: "incremental_per_section", value: b.incremental_per_section });
        commit("SET_CARATS", { forKey: "peProg", value: b.e_store_progression });

        return res
      })
    },
  },
  getters: {},
};
