<template>
  <div class="carat">
    <div class="carat_head" :style="{ borderColor: color}">
      <div class="carat_head_img">
        <img :src="img" alt="" />
      </div>
      <h3 class="carat_head_title">{{name}}</h3>
    </div>
    <ul class="carat_content">
      <slot name="bodyData">
          No Data
      </slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  computed: {
  },
  data() {
    return {};
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    img: { },
  },
  mounted() {},
  watch: {
    name() {},
  },
  methods: {
  },
};
</script>
