<template>
  <div class="mobile_view" v-if="true" @click="mobileViewBtn">
    <a href="#" class="mobile_view_btn">
      <div class="mobile_view_btn_icon">
        <img :src="mobileImg" alt="" />
      </div>
      <!-- <p class="mobile_view_btn_text">Mobile View</p> -->
    </a>
  </div>
</template>

<script>
export default {
  name: "MobileBtn",
  computed: {
    mobileImg() {
      return !this.$store.state.mobileView ? 
        require('../assets/MOB.svg') : 
        require('../assets/DESK.svg');
    }
  },
  methods: {
    mobileViewBtn(){
      this.$store.commit("SET_MOBILE_VIEW");
    }
  }
};
</script>
