<template>
    <div>
        <FBPP v-if="$store.state.storeType == 'Pure Player'"/>
        <FBBRICK v-if="$store.state.storeType !== 'Pure Player'"/>
    </div>
</template>

<script>
import FBBRICK from "./FrequentlyBoughtBrick";
import FBPP from "./FrequentlyBoughtPP";
export default {
  name: 'Search',
  components: { 
    FBBRICK,
    FBPP
  }
}
</script>