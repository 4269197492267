import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import {carats} from './carats'
import {caratshome} from './caratshome'
import {cart} from './cart'
import {insights} from './insights'
import {channel} from './channel'
import {brandstore} from './brandstore'
import {initialCaratSettingValue} from './initialCaratSettingValue'
import {report} from './report'
import { filter } from 'lodash'

Vue.use(Vuex)

var store = new Vuex.Store({
  state: {
    mobileView: false,
    currentUser: getSavedState('auth.currentUser'),
    token: getSavedState('token'),
    genralInfo: {
      "brands": [
          "Select Brand",
          "Energizer",
          "Private Label",
          "Duracell",
          "Panasonic",
          "Varta"
      ],
      "logo": "https://dupes-stage-data.s3-eu-west-1.amazonaws.com/channel/1/VnE0WqfkLEmIlwTLPYlbYhcgb6EcI3B2WAYwBdfw.png",
      "favicon": "https://dupes-stage-data.s3-eu-west-1.amazonaws.com/channel/1/4D4aUiG2otHEHT7RH7gRMXA6tUIA7BIGSqlxEzQO.png",
      "site_banner": "https://dupes-stage-data.s3-eu-west-1.amazonaws.com/channel/1/o25icxH6F2Wtn4aCkzfk5tIir7V5vnqGpn2oFd4B.jpeg",
      "mini_cart_banner": null,
      "checkout_banner": null
    },
    selectedBrands: [
      "Select Brand",
      "Energizer",
      "Private Label",
      "Duracell",
      "Panasonic",
      "Varta"
    ],
    categories: [],
    selectedCategory: {
      "id": 2,
      "slug": "electronics-computers",
      "name": "Electronics & Computers",
      "category_icon_path": null,
      "description": "<p>Electronics &amp; Computers</p>"
    },
    showLoader: false,
    products: {
      data: [],
      meta: {
        current_page: 1,
        last_page: 1,
        total : ''
      },
      links: {
        next: ''
      }
    },
    favProducts: {
      data: []
    },
    productsAll: {
      data: [],
      meta: {
        current_page: 1,
        last_page: 1,
        total : ''
      },
      links: {
        next: ''
      }
    },
    videoProduct: {
      data: [],
      meta: {
        current_page: 1,
        last_page: 1,
        total : ''
      },
      links: {
        next: ''
      }
    },
    productDetails: {
        "id": 4,
        "type": "simple",
        "name": "Duracell Optimum AA, Alkaline Batteries [Pack of 6], 1.5 V LR6 MX1500",
        "url_key": "duracell-optimum-aa-alkaline-batteries-pack-of-6-15-v-lr6-mx1500",
        "price": "10.9900",
        "formated_price": "$10.99",
        "short_description": "<p>Duracell Optimum AA, Alkaline Batteries [Pack of 6], 1.5 V LR6 MX1500</p>",
        "description": "<p>Duracell Optimum AA, Alkaline Batteries [Pack of 6], 1.5 V LR6 MX1500</p>",
        "sku": "1",
        "images": [],
        "base_image": {
            "small_image_url": process.env.VUE_APP_BACKEND_URL + "/vendor/webkul/ui/assets/images/product/small-product-placeholder.png",
            "medium_image_url": process.env.VUE_APP_BACKEND_URL + "/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
            "large_image_url": process.env.VUE_APP_BACKEND_URL + "/vendor/webkul/ui/assets/images/product/large-product-placeholder.png",
            "original_image_url": process.env.VUE_APP_BACKEND_URL + "/vendor/webkul/ui/assets/images/product/large-product-placeholder.png"
        },
        "variants": [],
        "in_stock": false,
        "reviews": {
            "total": 0,
            "total_rating": 0,
            "average_rating": 0,
            "percentage": []
        },
        "is_saved": false,
        "created_at": "2020-12-24T07:22:52.000000Z",
        "updated_at": "2020-12-24T07:22:52.000000Z",
        "bullet_points": [
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        ],
        "secondary_images": [
            "https://dupes-stage-data.s3-eu-west-1.amazonaws.com/product/4/5Dl5KtNWGZ4kG2R3oJG4RLdfAAp1ioNCUe8xcuYW.jpeg"
        ],
        "rich_content_cards": [],
        "brand": "Select Brand",
        "is_sponsored_brand": "Yes",
        "is_sponsored": "No",
        "is_endorsement": "No",
        "is_organic": "Yes",
        "custom_rating": "5",
        "custom_review": "25877",
        "keyword": "AA batteries",
        "battery_format": "",
        "pack_count": "",
        "is_efficient_assortment": "No",
        "optimized_title": "",
        "secondary_video": "",
        "rich_content_video": "",
        "related_products": [
            {
                "id": 5,
                "name": "Duracell Optimum AA, Alkaline Batteries [Pack of 8], 1.5 V LR6 MX1500",
                "battery_format": "AAA",
                "pack_count": "",
                "mobile_first_image": null
            },
            {
                "id": 6,
                "name": "Duracell Optimum AA, Alkaline Batteries [Pack of 12], 1.5 V LR6 MX1500",
                "battery_format": "AA",
                "pack_count": "",
                "mobile_first_image": null
            },
            {
                "id": 41,
                "name": "Duracell Optimum AAA, Alkaline Batteries [Pack of 6], 1.5 V LR6 MX1500",
                "battery_format": "AAA",
                "pack_count": "6",
                "mobile_first_image": null
            }
        ],
        "cross_sell_products": []
    },
    showMiniCart: false,
    keywordBanner: '',
    storeType: 'Pure Player'
  },
  mutations: {
    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue
      saveState('auth.currentUser', newValue)
      setDefaultAuthHeaders(state)
    },
    SET_CURRENT_USER_TOKEN(state, newValue) {
      state.token = newValue
      saveState('token', newValue)
      setDefaultAuthHeaders(state)
    },
    SET_CATEGORIES(state, newValue) {
      state.categories = newValue
    },
    SET_PRODUCTS(state, newValue) {
      state.products = newValue
    },
    SET_FAV_PRODUCTS(state, newValue) {
      state.favProducts = newValue
    },
    SET_KEYWORD_BANNER(state, newValue) {
      state.keywordBanner = newValue
    },
    SET_PRODUCT_DETAIL(state, newValue) {
      state.productDetails = newValue
    },
    SET_ALL_PRODUCTS(state, newValue) {
      state.productsAll = newValue
    },
    SET_VIDEO_PRODUCT(state, newValue) {
      state.videoProduct = newValue
    },
    SET_SHOWMINICART(state, newValue) {
      state.showMiniCart = newValue
    },
    SET_LOADER(state, newValue) {
      state.showLoader = newValue
    },
    SET_GENERAL_INFO(state, newValue) {
      state.genralInfo = newValue
    },
    SET_SELECTED_BRANDS(state, newValue) {
      state.selectedBrands = newValue
    },
    SET_SELECTED_CATEGORY(state, newValue) {
      state.selectedCategory = newValue
    },
    SET_STORE_TYPE(state, newValue) {
      state.storeType = newValue
    },
    SET_MOBILE_VIEW(state) {
      state.mobileView = !state.mobileView
    }
  },
  actions: {
    init({ state, dispatch }) {
      setDefaultAuthHeaders(state)
      dispatch('validate')
    },
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
      if (getters.loggedIn) return dispatch('validate')
      return axios
        .post('/customer/login?token=true', { email, password })
        .then((response) => {
          const user = response.data
          commit('SET_CURRENT_USER', user.data)
          commit('SET_CURRENT_USER_TOKEN', user.token)
          commit('SET_CHANNELS', user.data.channels)
          commit('SET_SELECTED_CHANNEL', user.data.channels.length > 0 ? user.data.channels[0] : { code : ''})
          return user
        })
    },
    logOut({ commit }) {
      return axios
        .get('/customer/logout')
        .then(() => {
          commit('SET_CURRENT_USER', null)
          commit('SET_CURRENT_USER_TOKEN', null)
        })
    },
    setCategories({ commit }) {
      return axios
        .get('/category_list')
        .then((res) => {
          const categories = res.data.data
          commit('SET_CATEGORIES', categories)
        })
    },
    setGeneralInfo({ commit }) {
      return axios
        .get('/site_general_info')
        .then((res) => {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = res.data.favicon;
          const genralInfo = res.data
          commit('SET_GENERAL_INFO', genralInfo)
        })
    },
    getProducts({ commit }, url) {
      commit('SET_LOADER', true);
      var abc = url.split("://")[1];
      return axios
        .get(`https://${abc}`)
        .then((res) => {
          const products = res.data
          commit('SET_PRODUCTS', products)
          commit('SET_LOADER', false);
        })
    },
    getFavPageProduct({ commit }) {
      commit('SET_LOADER', true);
      return axios
        .get(`/products?channel=default&limit=1000&favourite=1`)
        .then((res) => {
          const favProduct = res.data
          commit('SET_FAV_PRODUCTS', favProduct)
          commit('SET_LOADER', false);
        })
    },
    getProductsAll({ commit }, { id, query}) {
      commit('SET_LOADER', true);
      var temp = "";
      console.log(id);
      if(query) {
        temp = `&search=${ query }`;
      }
      if(id) {
        temp += `&category_id=${ id }`;
      }
      return axios
        .get(`/products?order=asc&sort=position&video=null&limit=18&channel=${this.state.channel.selectedChannel.code}${temp}`)
        // .get(`/products`)
        .then((res) => {
          const products = res.data
          commit('SET_PRODUCTS', products)
          commit('SET_LOADER', false);
        })
    },
    getSonsoredProducts({ commit }, { id, query}) {
      commit('SET_LOADER', true);
      var temp = "";
      console.log(id);
      if(query) {
        temp = `&search=${ query }`;
      }
      if(id) {
        temp += `&category_id=${ id }`;
      }
      return axios
        .get(`/products?order=asc&sort=position?limit=1000&non_organic=1&channel=${this.state.channel.selectedChannel.code}${temp}`)
        .then((res) => {
          const products = res.data
          commit('SET_ALL_PRODUCTS', products)
          commit('SET_LOADER', false);
        })
    },
    getVideoSearchProduct({ commit }, { id, query}) {
      commit('SET_LOADER', true);
      var temp = "";
      console.log(id);
      if(query) {
        temp = `&search=${ query }`;
      }
      if(id) {
        temp += `&category_id=${ id }`;
      }
      return axios
        .get(`/products?order=asc&sort=position?limit=1000&video=1&channel=${this.state.channel.selectedChannel.code}${temp}`)
        .then((res) => {
          const products = res.data
          commit('SET_VIDEO_PRODUCT', products)
          commit('SET_LOADER', false);
        })
    },
    getProductDetail({ commit }, id) {
      commit('SET_LOADER', true);
      return axios
        .get(`/products/${id}?&channel=${this.state.channel.selectedChannel.code}`)
        .then((res) => {
          const products = res.data.data
          commit('SET_PRODUCT_DETAIL', products)
          commit('SET_LOADER', false);
        })
    },
    getKeywordBanner({ commit }, keyword) {
      return axios
        .get(`/banner_by_keyword?keyword=${keyword}`)
        .then((res) => {
          const banner = res.data.banner
          commit('SET_KEYWORD_BANNER', banner)
        })
    },
    showHideMiniCart({ commit, state }) {
      commit('SET_SHOWMINICART', !state.showMiniCart)
    },
    onlyShowMiniCart({ commit }) {
      commit('SET_SHOWMINICART', true)
    },
    setLoader({ commit, value }) {
      commit('SET_LOADER', value)
    },
    validate({ commit, state }) {
      if (!state.currentUser) return Promise.resolve(null)

      return axios
        .get(`/customer/get`)
        .then((response) => {
          const user = response.data.data
          commit('SET_CURRENT_USER', user)
          return user
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            commit('SET_CURRENT_USER', null)
            window.localStorage.removeItem("vuex")
          } else {
            console.warn(error)
          }
          return null
        })
    },
  },
  modules: {
    carats,
    caratshome,
    insights,
    cart,
    channel,
    brandstore,
    initialCaratSettingValue,
    report
  },
  getters: {
    loggedIn(state) {
      return !!state.currentUser
    },
    getProductDetailStyle(state) {
      var temp = [];
      for (let index = 0; index < state.productDetails.related_products.length; index++) {
        const o = state.productDetails.related_products[index];
        if(o.battery_format !== '' && !temp.includes(o.battery_format))
          temp.push(o.battery_format)
      }
      return temp;
    },
    sponsredProduct(state) {
      return filter(state.productsAll.data, function(o) {
        if(o.is_sponsored == 'Yes')
          return o;
      });
    },
    sponsredBrandProduct(state) {
      return filter(state.productsAll.data, function(o) {
        if(o.is_sponsored_brand == "Yes")
          return o;
      });
    },
    endorsementProduct(state) {
      var a =  filter(state.productsAll.data, function(o) {
        if(o.is_endorsement == "Yes")
          return o;
      });
      return a
    },
    normalAllProduct(state) {
      return filter(state.products.data, function(o) {
        if(o.is_organic == "Yes")
          return o;
      });
    },
  },
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production',
});
export default store
store.dispatch("init");
function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  var token = state.token ? state.token : ''
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  axios.defaults.headers.common['channel'] = `${state.channel.selectedChannel.code}`
  console.log("CHANNEL HEADER IN DEFAULT", axios.defaults.headers.common['channel']);
}
