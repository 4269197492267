<template>
    <!-- <div class="main-body show-hotspot"> -->
    <!-- <div class="main-body" :class="$store.state.storeType == 'Pure Player' ? ($store.state.mobileView ? 'pplayer mobile_version' : 'pplayer') : ($store.state.mobileView ? 'brclick mobile_version' : 'brclick')"> -->
    <div class="main-body" :class="mobileClass">
      <Carats />
      <div class="body">
        <HeaderComponent v-if="$store.state.storeType === 'Pure Player'"/>
        <HeaderComponentBrick v-if="$store.state.storeType !== 'Pure Player'"/>
        
        <CommanLoader v-if="$store.state.showLoader"/>
        
        <router-view :key="$route.fullPath"/>
        
        <MobileViewButton />

        <FooterComponent  v-if="$store.state.storeType === 'Pure Player'"/>
        <FooterComponentBrick v-if="$store.state.storeType !== 'Pure Player'"/>
      </div>
      <InsightsPanel />
      <div class="mini-cart" v-bind:class="[$store.state.showMiniCart ? 'show' : '']">
        <MiniCart />
      </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import HeaderComponentBrick from '@/components/HeaderComponentBrick.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import FooterComponentBrick from '@/components/FooterComponentBrick.vue'
import Carats from '../components/Carat'
import MiniCart from '@/components/MiniCart.vue'
import InsightsPanel from '@/components/InsightsPanel.vue'
import CommanLoader from '@/components/CommanLoader.vue'
import MobileViewButton from '@/components/MobileViewButton.vue'
import { authMethods } from '../store/helpers'
export default {
  name: 'MainPage',
  components: {
    HeaderComponent,
    HeaderComponentBrick,
    FooterComponent,
    FooterComponentBrick,
    Carats,
    InsightsPanel,
    MiniCart,
    CommanLoader,
    MobileViewButton
  },
  computed: {
    mobileClass() {
      let className = "";
      if(this.$store.state.storeType == 'Pure Player') {
        className = this.$store.state.mobileView ?  'pplayer mobile_version' :  'pplayer';
      } else {
        className = this.$store.state.mobileView ?  'brclick mobile_version' :  'brclick';
      }
      if(window.innerWidth < 480) {
        className += " mobile_version";
      }
      return className;
    }
  },
  data() {
    return { name: ''}
  },
  mounted() {
    this.setCategories();
    this.setInsights();
    this.getCartItems();

  },
  watch: {
    name() {}
  },
  methods: {
    ...authMethods
  }
}
</script>

<style lang="scss" scoped>
.main-body {
  // display: flex;
  .carats-parent {
    display: inline-block;
    vertical-align: top;
    width: $caratsWidth;
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    background: #FFFFFF;
    box-shadow: -5px 0px 39px #0000002E;
  }
  
  .body {
    display: inline-block;
    // width: 100%;
    width: calc( 100% - #{$caratsWidth} );
  }
  @media only screen and (max-width:480px) {
      .carats-parent {
        display: none;
      }
      .body {
        width: 100%;
      }
  }
  
}
</style>
