import { render, staticRenderFns } from "./Search2.vue?vue&type=template&id=8feb4890&scoped=true&"
import script from "./Search2.vue?vue&type=script&lang=js&"
export * from "./Search2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8feb4890",
  null
  
)

export default component.exports