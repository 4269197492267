<template>
  <section class="cart">
      <div class="cart_box">
          <h2 class="heading"> Shopping Cart </h2>
          <p class="pricetag">Price</p>
          <p style="margin-top: 3rem;" v-if="this.$store.state.cart.items.length == 0">Your Cart is currently empty</p>
          <div class="cart_list">
              <div class="cart_list_item" v-for="(item, i) in this.$store.state.cart.items" :key="i">
                  <!-- <input type="checkbox" name="item" id="item1" class="cart_list_item_checkbox"> -->
                  <label class="cart_list_item_checkbox">
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                  </label>
                  <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" alt="" @error="imageUrlAlt" class="cart_list_item_img">
                  <div class="cart_list_item_content">
                      <h2 class="title">
                          {{item.name}}
                      </h2>
                      <p class="instock">In Stock.</p>
                      <div class="deal">
                          <input type="checkbox" name="deal" id="">Today’s Deals <a href="#">Learn more</a>
                      </div>
                      <div class="qty">
                          Qty: <input type="number" name="qty" id="qty" value="1" max="10" min="1" @change="changeCartItemQty({id: item.id, qty: $event.target.value})">
                          <a @click="removeCartItem(item.id)">Delete</a> | <a href="#">Save for later </a>
                      </div>
                  </div>
                  <h3 class="price">{{item.price * (item.qty ? item.qty : 1)}}</h3>
              </div>
          </div>
          <frequently-bought :productDetails="productDetails" v-if="false"/>
          <div class="footer" v-if="this.$store.state.cart.items.length > 0">
            <img v-if="getValueFromObj(initialCaratSetting, 'iECheckout')" :src="$store.state.genralInfo.checkout_banner" alt="">
            <div class="footer_box">
                <p class="subtotal">
                    Subtotal ({{$store.getters.cartItems.length}} items): <span>{{$store.getters.totalCartValue}}</span>
                </p>
                <button class="checkout_btn" @click="gotoCart">Proceed to Checkout</button>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
import { authComputed, authMethods } from "@/store/helpers";
import FrequentlyBought from '../components/ProductDetailComponents/FrequentlyBought.vue';
export default {
  components: { FrequentlyBought },
    computed: {
      ...authComputed,

    //   data(){
    //       return this.$store.getters.cartItems
    //   },
    },
    watch:{
        data() {
            this.abc = this.$store.getters.cartItems
        }
    },
    data() {
        return {
            qtyArray: []
        }
    },
    methods: {
        ...authMethods,
        gotoCart() {
            this.$router.push({ name: 'Shipping' })
        },
        // getQty(item, qty) {

        //     return 1
        // },
    },
}
</script>

<style>

</style>