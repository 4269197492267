<template>
    <div>
        <SearchPP v-if="$store.state.storeType == 'Pure Player'"/>
        <SearchBrick v-if="$store.state.storeType !== 'Pure Player'"/>
    </div>
</template>

<script>
import SearchBrick from "./Search-brick";
import SearchPP from "./Search-pp";
export default {
  name: 'Search',
  components: { 
    SearchBrick,
    SearchPP
  }
}
</script>