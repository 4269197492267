import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState({
    currentUser: (state) => state.currentUser,
    categories: (state) => state.categories,
    products: (state) => state.products,
    favProducts: (state) => state.favProducts,
    productDetails: (state) => state.productDetails,
    carats: (state) => state.carats,
    initialCaratSetting: (state) => state.initialCaratSettingValue.settings,
    storeMode: (state) => state.initialCaratSettingValue.storeMode,
    caratshome: (state) => state.caratshome,
    showMiniCart: (state) => state.showMiniCart,
    incremental_per_section: (state) => state.carats.incremental_per_section,
    cartItems: (state) => state.cart.items,
  }),
  ...mapGetters( ['loggedIn'] ),
}

export const authMethods = mapActions( 
  [
    'logIn', 
    'logOut', 
    'setCategories', 
    'getProducts', 
    'getProductsAll', 
    'getSonsoredProducts', 
    'getVideoSearchProduct', 
    'getProductById',
    'getProductDetail',
    'setCarats',
    'setPrevState',
    'setCaratsHome',
    'submitFrontForm',
    'setCaratsOpen',
    'showHideMiniCart',
    'onlyShowMiniCart',
    'setInsights',
    'setParticularInsights',
    'getCartItems',
    'addCartItems',
    'removeCartItem',
    'changeCartItemQty',
    'setGeneralInfo',
    'exportPDF',
    'getKeywordBanner',
    'getBrandStoreData',
    'getFavPageProduct',
    'getCaratsValue',
    'setInitialSettings',
    'setCaratsSettings',
    'setStoreMode',
    'createStoreApi',
    'updateStoreApi',
    'deleteStoreApi',
    'getStoreData',
    'getSimulationsList'
  ]
)
