import axios from 'axios'

export const insights = {
  state: () => ({
    insightsAll: [
      {
        "id": 1,
        "title": "Glossary1",
        "sell_out_text": "Text1",
        "description": "This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.",
        "class": "Class1",
        "section": "Section1",
        "status": 1,
        "created_at": "2021-01-07T14:23:17.000000Z",
        "updated_at": "2021-01-07T14:23:17.000000Z"
      },
      {
        "id": 2,
        "title": "Glossary2",
        "sell_out_text": "Text2",
        "description": "This is Description2.This is Description2.This is Description2.This is Description2.This is Description2.This is Description2.This is Description2.",
        "class": "Class2",
        "section": "Section2",
        "status": 1,
        "created_at": "2021-01-07T14:23:48.000000Z",
        "updated_at": "2021-01-07T14:23:48.000000Z"
      },
      {
        "id": 3,
        "title": "Glossary3",
        "sell_out_text": "Text3",
        "description": "This is Description3.This is Description3.This is Description3.This is Description3.This is Description3.This is Description3.This is Description3.",
        "class": "Class3",
        "section": "Section3",
        "status": 1,
        "created_at": "2021-01-07T14:24:18.000000Z",
        "updated_at": "2021-01-07T14:24:18.000000Z"
      }
    ],
    particularInsight: {
      "id": 1,
      "title": "Glossary1",
      "sell_out_text": "Text1",
      "description": "This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.",
      "class": "Class1",
      "section": "Section2",
      "status": 1,
      "created_at": "2021-01-07T14:23:17.000000Z",
      "updated_at": "2021-01-07T14:23:17.000000Z"
    }
  }),
  mutations: {
    SET_INSIGHTS(state, payload) {
      state.insightsAll = payload;
    },
    SET_PARTI_INSIGHTS(state, payload) {
      state.particularInsight = payload;
    },
  },
  actions: {
    setInsights({ commit }) {
      return axios
        .get('/glossary')
        .then((res) => {
          const glossary = res.data.data
          commit('SET_INSIGHTS', glossary)
        })
    },
    setParticularInsights({ commit }, keyValue) {
      document.getElementsByClassName("insightspanel")[0].style.right = 0
      var abc = null;
      this.state.insights.insightsAll.map((val)=> {
        if(val.section == keyValue) {
          abc = val
        } 
      }, abc)
      if (abc) {
        commit('SET_PARTI_INSIGHTS', abc)
      } else {
        commit('SET_PARTI_INSIGHTS', {
          "id": 1,
          "title": "Dummy Content",
          "sell_out_text": "Dummy Text",
          "description": "This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.This is Description1.",
          "class": "#000",
          "section": "Section2",
          "status": 1,
          "created_at": "2021-01-07T14:23:17.000000Z",
          "updated_at": "2021-01-07T14:23:17.000000Z"
        })
      }
    },
  },
};
/*
mobile_first_img : Mobile First Image
video : Video Hotspot
optimized_title : Optimized Title
review: Review
size: Size Name Hotspot
style: Style Name AAA 
bullet_points: Product detail Points
product_desc: product description
from_manufacturer : From the manufacturer 
custom_review: Custom Reviews
frequntly_bought: Frequently bought together
sponsored_brand: Sponsored Brand Product
sponsored_product: Sponsered Product
search_banner: Search page Banner
*/