<template>
    <main>
        <section class="login">
            <div class="logo">
                <img src="../assets/logo_black.svg" alt="">
            </div>
            <div class="login_box">
                <div class="header">
                    <h1 class="head">Reset your password</h1>
                    <p class="subtitle">Enter your email address and we will send you a password reset link.</p>
                </div>
                <form action="#">
                    <div class="field">
                        <div class="field_box active">
                            <label for="email">Email Address</label>
                            <input type="email" placeholder="someone@gmail.com">
                        </div>
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <!-- <button>Submit</button> -->
                    <a class="submit_btn" href="pass-success.html">Submit</a>
                    <a href="#" class="forgot_link hide">Forgot password?</a>
                    <a href="#" class="back_btn hide"><span></span> Back to Sign In</a>
                </form>
            </div>
        </section>
    </main>
</template>

<script>
export default {
  name: '',
  components: {},
  computed: {},
  data() {
    return { name: ''}
  },
  mounted() {
    
  },
  watch: {
    name() {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
