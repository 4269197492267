import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {find} from 'lodash'
import BackBtn from './components/BackBtn.vue'

require("../node_modules/slick-carousel/slick/slick.css");

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + '/api/';
axios.baseCalcURL = process.env.VUE_APP_BACKEND_CALC_URL;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false

Vue.prototype.$document = document;
Vue.prototype.capitalize = function (str) { return str[0].toUpperCase() + str.substr(1, ); };
Vue.prototype.formatToThousand = function (amount, decimalCount = 2, thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
  } catch (e) {
    console.log(e)
  }
};
Vue.prototype.getKeyWord = function (str) { return str ? str : 'battery'; };
Vue.prototype.getProductListAPIAttr = function () { return '?order=asc&sort=position&video=null&limit=18' };
Vue.prototype.imageUrlAlt = (event) => { event.target.src = require('./assets/basic.png') },
Vue.prototype.getVideoUrl = (url) => {
  if(url) {
    var id = url.split("=")[1]
    return `https://www.youtube.com/embed/${id}`
  } else {
    return `https://www.youtube.com/embed/`
  }
},
Vue.prototype.getVideoThumb = (url) => { var id = url.split("=")[1]; return `https://img.youtube.com/vi/${id}/0.jpg` },

Vue.prototype.getValueFromDupes = function (obj, key) {
  var a = find(obj, { 'code': key });
  return a ? a.value : ''
};
Vue.prototype.getValueFromObj = function (obj, key) {
  var a = find(obj, { 'key': key });
  return a ? a.toggle_value == "1" ? true : false : false
};

Vue.component('back-btn', BackBtn)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
