<template>
    <div class="row" v-if="getValueFromObj(initialCaratSetting, 'iEFrequentlyBought') && productDetails.cross_sell_products.length > 0">
        <div class="col-lg-12">
            <div class="pd_frequntly bg_white">
                <div class="product_box" :to="`/product-detail/${item.id}`" v-for="(item, i) in productDetails.cross_sell_products.slice(0,1)" :key="i">
                    <h3 class="title">Frequently bought together <hot-spot keyValue="frequntly_bought"/></h3>
                    <div class="product_box_img">
                        <router-link :to="`/product-detail/${item.id}`" >
                            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                        </router-link>
                    </div>
                    <div class="product_box_content">
                        <h2 class="product_box_content_title"> 
                            {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                            <hot-spot classValue="main-title" keyValue="optimized_title" v-if="getValueFromObj(initialCaratSetting, 'iEOptimized') && i==0"/>
                        </h2>
                        <h3 class="product_box_content_price">
                            {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                        </h3>
                        <button class="brc_btn" @click="addCartItems(productDetails.cross_sell_products[0]); showHideMiniCart();">Add</button>
                        <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                            <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                            <router-link :to="`/product-detail/${item.id}`" >
                                {{ item.custom_review }} reviews
                            </router-link>
                            <hot-spot keyValue="custom_review"  v-if="i==0"/>
                        </div>
                        <h3 class="product_box_content_price" v-if="$store.state.storeType == 'Pure Player'">
                            {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                        </h3>
                    </div>
                </div>
                <div class="pd_frequntly_items" v-if="false">
                    <ul class="pd_frequntly_items_list">
                        <router-link :to="`/product-detail/${item.id}`" v-for="(item, i) in productDetails.cross_sell_products.slice(0,1)" :key="i">
                            <li class="pd_frequntly_items_pimg">
                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                            </li>
                        </router-link>
                        <!-- </div> -->
                        <li class="pd_frequntly_items_total">
                            <p>Total price: <span>{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{calculatePrice(productDetails.price, productDetails.cross_sell_products[0].price)}}</span></p>
                            <a @click="addCartItems(productDetails.cross_sell_products[0]); showHideMiniCart();" class="pd_frequntly_items_total_cart_btn">
                                <img src="@/assets/add_cart.svg" alt=""><span>Add to Cart</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="pd_frequntly_items_names" v-if="false">
                        <li>
                            <input type="checkbox" name="item" id=""><span>This item:</span> Monster Jam Authentic Grave Digger RC, 1:24 Scale, 2.4 GHz, for Ages 4 and Up <span class="price">15.96€</span>
                        </li>
                        <li class="active">
                            <input type="checkbox" name="item" id="">Duracell Ultra AAA Alkaline Batteries [Pack of 8], 1.5 V LR03 MN2400 <span class="price">7.00€</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authMethods, authComputed } from "@/store/helpers";
import HotSpot from '@/components/HotSpot.vue';
export default {
    name: 'FrequentlyBought',
    components: {
        HotSpot,
    },
    computed: {
        ...authComputed,
    },
    methods: {
        ...authMethods,
        calculatePrice(a, b) {
            return parseInt(a) + parseInt(b);
        },
    }
}
</script>

<style>

</style>