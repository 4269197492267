<template>
    <main class="A4">
        <!-- <button @click="printPage">Print</button> -->
        <div class="sheet">
            <table class="logo">
                <tr>
                    <td><img src="../assets/logo_black.svg" alt=""></td>
                    <td><h3>{{ $store.state.channel.selectedChannel.code }}  - VALUE CREATION scorecard</h3></td>
                    <td><p>{{ $store.state.storeType }}</p></td>
                </tr>
            </table>
            <table class="table">
                <thead>
                    <tr>
                        <th>SECTION</th>
                        <th>FEATURE</th>
                        <th>current execution status</th>
                        <th>execution agrement</th>
                        <th v-if="false">growth index</th>
                        <th>Section Incremental Revenues</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in reportData.sections.Search.features" :key="item.feature">
                        <td v-if="i == 0" :rowspan="reportData.sections.Search.features.length" style="background: #F5F5F5; padding: 10px 0; text-align: center;">
                            <img src="../assets/carats/pink-carats.svg" alt="">
                            <p>Search</p>
                        </td>
                        <td>{{ item.feature }}</td>
                        <td>{{ item.current_execution }}</td>
                        <td>{{ item.execution_agreement ? 'Yes' : 'No' }}</td>
                        <td v-if="false">{{ item.growth_index }}</td>
                        <td 
                            :rowspan="reportData.sections.Search.features.length" 
                            v-if="i == 0"
                        >
                            +{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.sections.Search.section_incremental) }}
                        </td>
                    </tr>
                    <tr v-for="(item, i) in reportData.sections.Assortment.features" :key="item.feature">
                        <td v-if="i == 0" :rowspan="reportData.sections.Assortment.features.length" style="background: #F5F5F5; padding: 10px 0; text-align: center;">
                            <img src="../assets/carats/yellow-carats.svg" alt="">
                            <p>Assortment</p>
                        </td>
                        <td>{{ item.feature }}</td>
                        <td>{{ item.current_execution }}</td>
                        <td>{{ item.execution_agreement ? 'Yes' : 'No' }}</td>
                        <td v-if="false">{{ item.growth_index }}</td>
                        <td 
                            :rowspan="reportData.sections.Assortment.features.length" 
                            v-if="i == 0"
                        >
                            +{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.sections.Assortment.section_incremental) }}
                        </td>
                    </tr>
                    <tr v-for="(item, i) in reportData.sections.Content.features" :key="item.feature">
                        <td v-if="i == 0" :rowspan="reportData.sections.Content.features.length" style="background: #F5F5F5; padding: 10px 0; text-align: center;">
                            <img src="../assets/carats/green-carats.svg" alt="">
                            <p>Content</p>
                        </td>
                        <td>{{ item.feature }}</td>
                        <td>{{ item.current_execution }}</td>
                        <td>{{ item.execution_agreement ? 'Yes' : 'No' }}</td>
                        <td v-if="false">{{ item.growth_index }}</td>
                        <td 
                            :rowspan="reportData.sections.Content.features.length" 
                            v-if="i == 0"
                        >
                            +{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.sections.Content.section_incremental) }}
                        </td>
                    </tr>
                    <tr v-for="(item, i) in reportData.sections['Rates Reviews'].features" :key="item.feature">
                        <td v-if="i == 0" :rowspan="reportData.sections['Rates Reviews'].features.length" style="background: #F5F5F5; padding: 10px 0; text-align: center;">
                            <img src="../assets/carats/blue-carats.svg" alt="">
                            <p>Rates & Reviews</p>
                        </td>
                        <td>{{ item.feature }}</td>
                        <td>{{ item.current_execution }}</td>
                        <td>{{ item.execution_agreement ? 'Yes' : 'No' }}</td>
                        <td v-if="false">{{ item.growth_index }}</td>
                        <td 
                            :rowspan="reportData.sections['Rates Reviews'].features.length" 
                            v-if="i == 0"
                        >
                            +{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.sections['Rates Reviews'].section_incremental) }}
                        </td>
                    </tr>
                    <tr v-for="(item, i) in reportData.sections['Active X Selling'].features" :key="item.feature">
                        <td v-if="i == 0" :rowspan="reportData.sections['Active X Selling'].features.length" style="background: #F5F5F5; padding: 10px 0; text-align: center;">
                            <img src="../assets/carats/pink-carats.svg" alt="">
                            <p>ACTIVE X-SELLING</p>
                        </td>
                        <td>{{ item.feature }}</td>
                        <td>{{ item.current_execution }}</td>
                        <td>{{ item.execution_agreement ? 'Yes' : 'No' }}</td>
                        <td v-if="false">{{ item.growth_index }}</td>
                        <td 
                            :rowspan="reportData.sections['Active X Selling'].features.length" 
                            v-if="i == 0"
                        >
                            +{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.sections['Active X Selling'].section_incremental) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <span class="desc">*estimation based on best available studies and/or business cases and weighted current execution on retailer's website</span>
                        </td>
                        <td v-bind:style="{ backgroundImage: 'url(' + require('../assets/bg.png') + ')' }" >
                            <h4>Estimated Value Creation: </h4>
                            <h3>+{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ formatToThousand(reportData.potential_value) }}</h3>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="sheet">
            <div class="ct-chart"></div>
            <table class="chart_point" v-if="false">
                <tbody>
                    <tr>
                        <td>e-base</td>
                        <td>100.000</td>
                        <td>100%</td>
                    </tr>
                    <tr>
                        <td><img src="../assets/carats/pink-carats.svg" alt=""> <span>Search</span></td>
                        <!-- <td><img src="../assets/carats/pink-carats.svg" alt=""> <span>Search</span></td> -->
                        <td>100.000</td>
                        <td>100%</td>
                        <td>{{reportData.sections.Search.section_incremental}}</td>
                        <td>{{reportData.sections.Search.section_incremental_pct}}%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><img src="../assets/carats/yellow-carats.svg" alt=""> <span>Assortment </span></td>
                        <td>100.000</td>
                        <td>100%</td>
                        <td>{{reportData.sections.Search.section_incremental}}</td>
                        <td>{{reportData.sections.Search.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Assortment.section_incremental}}</td>
                        <td>{{reportData.sections.Assortment.section_incremental_pct}}%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><img src="../assets/carats/green-carats.svg" alt=""> <span>Content</span></td>
                        <td>100.000</td>
                        <td>100%</td>
                        <td>{{reportData.sections.Search.section_incremental}}</td>
                        <td>{{reportData.sections.Search.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Assortment.section_incremental}}</td>
                        <td>{{reportData.sections.Assortment.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Content.section_incremental}}</td>
                        <td>{{reportData.sections.Content.section_incremental_pct}}%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><img src="../assets/carats/blue-carats.svg" alt=""> <span>rates & reviews </span></td>
                        <td>100.000</td>
                        <td>100%</td>
                        <td>{{reportData.sections.Search.section_incremental}}</td>
                        <td>{{reportData.sections.Search.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Assortment.section_incremental}}</td>
                        <td>{{reportData.sections.Assortment.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Content.section_incremental}}</td>
                        <td>{{reportData.sections.Content.section_incremental_pct}}%</td>
                        <td>{{reportData.sections['Rates Reviews'].section_incremental}}</td>
                        <td>{{reportData.sections['Rates Reviews'].section_incremental_pct}}%</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><img src="../assets/carats/red-carats.svg" alt=""> <span>ACTIVE X-SELLING </span></td>
                        <td>100.000</td>
                        <td>100%</td>
                        <td>{{reportData.sections.Search.section_incremental}}</td>
                        <td>{{reportData.sections.Search.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Assortment.section_incremental}}</td>
                        <td>{{reportData.sections.Assortment.section_incremental_pct}}%</td>
                        <td>{{reportData.sections.Content.section_incremental}}</td>
                        <td>{{reportData.sections.Content.section_incremental_pct}}%</td>
                        <td>{{reportData.sections['Rates Reviews'].section_incremental}}</td>
                        <td>{{reportData.sections['Rates Reviews'].section_incremental_pct}}%</td>
                        <td>{{reportData.sections['Active X Selling'].section_incremental}}</td>
                        <td>{{reportData.sections['Active X Selling'].section_incremental_pct}}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="report no-printme ">
            <button class="submit_btn" @click="$router.push({ path: '/'})">Back</button>
            <button class="submit_btn" @click="printPage">Print PDF</button>
        </div>
    </main>
</template>

<script>
import Chartist  from "chartist";
export default {
  name: 'Report',
  components: {},
  computed: {
      reportData() {
          return this.$store.state.report.reportData
      }
  },
  data() {
    return {
      
    }
  },
  mounted() {
    // console.log("VIVEK IN GRAPH", this.$store.state.report.reportData);
    var a = this.reportData.sections.Search.section_incremental_pct
    var b = this.reportData.sections.Assortment.section_incremental_pct
    var c = this.reportData.sections.Content.section_incremental_pct
    var d = this.reportData.sections['Rates Reviews'].section_incremental_pct
    var e = this.reportData.sections['Active X Selling'].section_incremental_pct

    var aa = this.reportData.sections.Search.section_incremental
    var bb = this.reportData.sections.Assortment.section_incremental
    var cc = this.reportData.sections.Content.section_incremental
    var dd = this.reportData.sections['Rates Reviews'].section_incremental
    var ee = this.reportData.sections['Active X Selling'].section_incremental
    var max = a+b+c+d+e+150;
    var arrValue = [
        '100% - 100.000',
        `${a}% - ${aa}`,
        `${b}% - ${bb}`,
        `${c}% - ${cc}`,
        `${d}% - ${dd}`,
        `${e}% - ${ee}`
    ];
    var defaultOptions = {
        labelClass: `ct-label`,
        labelOffset: {
        x: 0,
        y: -10
        },
        textAnchor: 'middle',
        labelInterpolationFnc: Chartist.noop
    };
    Chartist.plugins = Chartist.plugins || {};
    Chartist.plugins.ctPointLabels = function(options) {

        options = Chartist.extend({}, defaultOptions, options);

        return function ctPointLabels(chart) {
        
        if(chart instanceof Chartist.Bar) {
            
            chart.on('draw', function(data) {
            if(data.type === 'bar') {
                if(data.seriesIndex == 5) {
                    data.group.elem('text', {
                        x: data.x1,
                        y: data.y2 - 10,
                        style: 'text-anchor: ' + options.textAnchor
                    }, 'aaaaaa').text( 'est. ' + arrValue[data.index] );
                }
                
            }
            });
        }
        };
    };
    new Chartist.Bar('.ct-chart', {
        labels: ['e-BASE', 'SEARCH', 'ASSORTMENT', 'CONTENT', 'RATES & REVIEWS', 'ACTIVE X-SELLING'],
        series: [
        [100, 100, 100, 100, 100, 100],
        [0, a, a, a, a, a],
        [0, 0, b, b, b, b],
        [0, 0, 0, c, c, c],
        [0, 0, 0, 0, d, d],
        [0, 0, 0, 0, 0, e],
        ]
    }, {
        classNames: {
        // chart: 'ct-chart-line',
        // label: 'ct-label-vivek',
        // labelGroup: 'ct-labels',
        // series: 'ct-series',
        // line: 'ct-line',
        // point: 'ct-point',
        // area: 'ct-area',
        // grid: 'ct-grid',
        // gridGroup: 'ct-grids',
        // vertical: 'ct-vertical',
        // horizontal: 'ct-horizontal',
        // start: 'ct-start',
        // end: 'ct-end'
        },
        chartPadding: {
        top: 20,
        right: 5,
        bottom: 20,
        left: 5
        },
        stackBars: true,
        height: 480,
        showGrid: false,
        plugins: [
            Chartist.plugins.ctPointLabels({
                textAnchor: 'middle'
            })
        ],
        axisY: {
        type: Chartist.FixedScaleAxis,
        ticks: [0, 50.00, 100.00, 150.00, 200.00, 250.00, 300.00, 350.00, 400.00, 450.00, 500.00, 550.00],
        low: 0,
        high: max,
        labelInterpolationFnc: function (value) {
            return (value) + '.00';
        }
        },
        axisX: {
        labelInterpolationFnc: function (value) {
            return `${value}`;
        }
        }
    }).on('draw', function (data) {
        if (data.type === 'bar') {
        data.element.attr({
            style: 'stroke-width: 45px'
        });
        }
    });
    setTimeout(() => {
        // window.print();
    }, 1000);
  },
  watch: {
    name() {}
  },
  methods: {
    printPage() {
        // var css = '@page { size: landscape; }',
        //     head = document.head || document.getElementsByTagName('head')[0],
        //     style = document.createElement('style');

        // style.type = 'text/css';
        // style.media = 'print';

        // if (style.styleSheet){
        //     style.styleSheet.cssText = css;
        // } else {
        //     style.appendChild(document.createTextNode(css));
        // }

        // head.appendChild(style);
        // var domToPdf = require('dom-to-pdf');

        // var element = document.getElementById('test');
        // var options = {
        // filename: 'test.pdf'
        // };
        // domToPdf(element, options, function() {
        // console.log('done');
        // });
        window.print();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
