<template>
    <div class="pd_content_additional">
        <h2 class="title">{{ additionalItem.title }}</h2>
        <div class="pd_content_additional_box">
            <div class="pd_content_additional_box_img">
                <img src="@/assets/additional.jpg" alt="">
            </div>
            <div class="pd_content_additional_box_content">
                <p>
                    {{ additionalItem.subtitle }}
                    <span>
                        {{ additionalItem.desc }} <span class="price" v-if="additionalItem.price">{{additionalItem.price}}</span>
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FrequentlyBought',
    data() {
        return {
            additionalItem: {
                img: require('@/assets/additional.jpg'),
                title: 'Additional item to go with this product:',
                subtitle: 'Up to 15% off Gift Cards from Top Brands',
                desc: 'Great deals from top brands iTunes, New Look, Dining Out, Footlocker and many more. Buy now',
                price: '6.99€'
            },
        }
    }
}
</script>

<style>

</style>