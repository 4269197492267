<template>
  <button @click="$document.documentElement.scrollTop = 0" class="back_to_top">
    Back to top
  </button>
</template>

<script>
export default {};
</script>

<style></style>