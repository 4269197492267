<template>
  <li>
    <div class="point">{{ name }}
      <div class="radio_list" v-if="radioList">
        <label class="cc_radio" v-for="(item, i) in radioList.items" :key="i">
            <input type="radio" :name="radioList.keyValue" :value="item" @change="changeType($event.target.name, item)"/>
            <span class="checkmark"></span>
            <span class="text">{{item}}</span>
        </label>
      </div>
    </div>
    <div class="body">
      <label class="c_radio">
        <input
          type="radio"
          :name="keyValue"
          :checked="isChecked(0)"
          value="0"
          @change="changeRadio($event.target.name, $event.target.value)"
          :disabled="storeMode == 'select'"
        />
        <span class="checkmark"></span>
        <span class="text"> &lt;70%</span>
      </label>
      <label class="c_radio">
        <input
          type="radio"
          :name="keyValue"
          :checked="isChecked(1)"
          value="1"
          @change="changeRadio($event.target.name, $event.target.value)"
          :disabled="storeMode == 'select'"
        />
        <span class="checkmark"></span>
        <span class="text">70 - 90%</span>
      </label>
      <label class="c_radio">
        <input
          type="radio"
          :name="keyValue"
          :checked="isChecked(2)"
          value="2"
          @change="changeRadio($event.target.name, $event.target.value)"
          :disabled="storeMode == 'select'"
        />
        <span class="checkmark"></span>
        <span class="text"> ≥90%</span>
      </label>
    </div>
  </li>
</template>

<script>
import { authComputed, authMethods } from "@/store/helpers";
import { find } from "lodash";
export default {
  name: "",
  components: {},
  computed: {
    ...authComputed,
  },
  data() {
    return {};
  },
  props: {
    keyValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    radioList: {
      type: Object,
      default: null,
    },
  },
  mounted() {},
  watch: {
    name() {},
  },
  methods: {
    ...authMethods,
    changeRadio(key, value) {
      // this.setCaratsHome({ forKey: key, value: parseInt(value) });
      if(this.$store.state.initialCaratSettingValue.storeMode == 'create') {
        this.setCaratsSettings({ forKey: key, value: parseInt(value), toKey: 'value' });
      }
    },
    changeType(key, value) {
      // this.setCaratsHome({ forKey: key, value: value });
      this.setCaratsSettings({ forKey: key, value: value, toKey: 'value' });
    },
    isChecked(a) {
      // if (this.caratshome[this.keyValue] == a) return true;
      let obj = find(this.initialCaratSetting, { 'key': this.keyValue })
      if (obj && obj.value == a) return true;
      return false;
    },
  },
};
</script>
