// const loDash = require('lodash')
import axios from "axios";
export const initialCaratSettingValue = {
  state: () => ({
    settings: [],
    storeMode: 'select',
    selectedStore: {
      id: '',
      name: ''
    },
    listOfSimulations: [],
    selectedSimulation: { id: '', store_id: '', type: '', name: '' }
  }),
  mutations: {
    SET_INITAIL_CARAT_SETTINGS(state, payload) {
      state.settings = payload;
    },
    SET_STORE_MODE(state, payload) {
      state.storeMode = payload;
    },
    SET_SELECTED_STORE(state, payload) {
      state.selectedStore = payload;
    },
    SET_SELECTED_SIMULATION(state, payload) {
      state.selectedSimulation = payload;
    },
    SET_SIMULATION_LIST(state, payload) {
      state.listOfSimulations = payload;
    },
    SET_CARAT_SETTINGS(state, payload) {
      const settings = state.settings;
      let objIndex = settings.findIndex((obj => obj.key == payload.forKey));
      if(payload.toKey == 'value') {
        settings[objIndex].value = payload.value
      } else if(payload.toKey == 'toggle_value') {
        let a = settings[objIndex].toggle_value == null ? false : (settings[objIndex].toggle_value == "0" ? false : true) 
        settings[objIndex].toggle_value = !a
      } else if(payload.toKey == 'all') {
        settings[objIndex].toggle_value = true
      }
      state.settings = settings
    }
  },
  actions: {
    setInitialSettings({ commit }) {
      return axios
      .get('/list_carat_settings?token=true')
      .then((res) => {
        commit("SET_INITAIL_CARAT_SETTINGS", res.data.data);
        return res.data.data
      })
    },
    getSimulationsList( {dispatch}, {id, type, idForSimulation}) {
      return axios
      .get(`/list_store_simulations?token=true&store_id=${id}`)
      .then((res) => {
        type == 'INITIAL' ? 
          dispatch('getSimulationsSettings', res.data.data[0].id) : 
          dispatch('getSimulationsSettings', idForSimulation) 
        // this.getSimulationsSettings(res.data.data[0].id);
        return res.data.data
      })
    },
    getSimulationsSettings( {commit, dispatch }, id) {
      return axios
      .get(`/list_simulation_settings?token=true&simulation_id=${id}`)
      .then((res) => {
        commit("SET_INITAIL_CARAT_SETTINGS", res.data.data);
        dispatch('submitFrontForm')
        return res.data.data
      })
    },
    deleteSimulationsSettings( {commit }, id) {
      return axios
      .get(`/delete_store_simulation?token=true&simulation_id=${id}`)
      .then((res) => {
        // commit("SET_INITAIL_CARAT_SETTINGS", res.data.data);
        console.log(commit);
        return res.data.data
      })
    },
    createSimulationsSettings( {commit }, payload) {
      // https://staging.duracell-perfect-store.com/api/save_store_simulation
      return axios
      .post(`/save_store_simulation?token=true`, payload)
      .then((res) => {
        console.log(commit, res);
        return res.data.data
      })
    },
    setCaratsSettings({ commit }, value) {
      commit("SET_CARAT_SETTINGS", value);
    },
    setStoreMode({ commit }, value) {
      commit("SET_STORE_MODE", value);
    },
    setSimulationsList({ commit }, value) {
      commit("SET_SIMULATION_LIST", value);
    },
    setSelectedSimulations({ commit }, value) {
      commit("SET_SELECTED_SIMULATION", value);
    },
    createStoreApi({commit}, data) {
      return axios
      .post('/save_channel_store?token=false', data,  {
        headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data;`,
        }
      })
      .then((res) => {
        commit('SET_SELECTED_STORE', res.data.data)
        return res.data.data
      })
    },
    updateStoreApi({commit, dispatch}, data) {
      return axios
      .post('/update_channel_store?token=false', data)
      .then((res) => {
        dispatch('validate');
        commit('SET_SELECTED_STORE', res.data.data)
        return res.data.data
      })
    },
    deleteStoreApi({ commit, dispatch, rootState }, id) {
      let stores = rootState.currentUser.stores;
      return axios
      .get(`/delete_channel_store?token=false&store_id=${id}`)
      .then((res) => {
        dispatch('validate');
        stores.length > 0 ? 
          commit('SET_SELECTED_STORE', stores[0]): 
          commit('SET_SELECTED_STORE', { id: '', name: '' });
        
        return res.data
      })
    },
    getStoreData({commit}, id) {
      return axios
      .get(`/get_store_details?token=false&store_id=${id}`)
      .then((res) => {
        commit("SET_STORE_TYPE", res.data.data.type == 1 ? 'Pure Player': 'Brick & Click');
        commit("SET_INITAIL_CARAT_SETTINGS", res.data.user_settings);
        return res.data.data
      })
    },
    
  },
  getters: {},
};
