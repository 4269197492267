<template>
    <footer>
      <back-btn />
      <div class="copyright">
          <ul>
              <li><a href="#">Accessibility</a></li>
              <li><a href="#">Cookie policy</a></li>
              <li><a href="#">Privacy policy</a></li>
              <li><a href="#">Contact us</a></li>
              <li><a href="#">Help center</a></li>
              <li><a href="#">Terms & conditions</a></li>
          </ul>
      </div>
  </footer>
</template>

<script>
export default {
  name: '',
  components: {},
  computed: {},
  data() {
    return { name: ''}
  },
  mounted() {},
  watch: {
    name() {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
