<template>
    <div>
      <!-- <RouterView :key="$route.fullPath" /> -->
      <RouterView />
    </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  computed: {},
  data() {
    return { name: ''}
  },
  mounted() {
  },
  watch: {
    name() {}
  },
  methods: {}
}
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
