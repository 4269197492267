<template>
  <div class="row" v-if="getValueFromObj(initialCaratSetting, 'iERichContent') && getKeyWord(keyword) == 'Battery'">
    <div class="col-lg-12">
        <div class="pd_manufacturer">
            <h3 class="pd_manufacturer_title">From the manufacturer <hot-spot keyValue="from_manufacturer"/></h3>
            <div class="pd_manufacturer_content">
                <ul>
                    <li v-for="(item, i) in data" :key="i">
                        <img :src="item.src" alt="" v-if="item.type == 'image'">
                        <iframe v-if="item.type == 'text'" :src="getVideoUrl(item.src)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </li>
                </ul>
            </div>
            <div class="pd_manufacturer_feature" v-if="false">
                <ul>
                    <li>Proprietary cathode with 2 active ingredients</li>
                    <li>Duracell Optimum AAA batteries provides Up to 100% Extra Life* or Extra Power* so that you get the best performance from devices.</li>
                    <li>EXTRA LIFE = Duracell Optimum will last longer in some devices like toys*  </li>
                    <li>*Delivers extra life in AAA-size batteries compared to the Minimum Average Duration of the 2015 IEC Toy test. For more info: Duracell’s website</li>
                    <li>EXTRA POWER = In other devices Duracell Optimum will deliver more power* than previous Duracell Alkaline to make them work better ( e.g. Spinmaster Monster Jam truck  1:24).</li>
                    <li>*Extra Power in a wide range of devices vs. next best Duracell alkaline AAA. Duracell’s website</li>
                    <li>OPTIMUM STORAGE: Optimum’s pack keeps new batteries in place and allows secure storage of used ones for recycling</li>
                    <li>100% RECYCLABLE PACKAGING: Optimum’s packaging is 100% recyclable and 0% plastic</li>
                    <li>*Delivers extra life in AAA-size batteries compared to the Minimum Average Duration of the 2015 IEC toy test or extra power in a wide range of devices vs. next best Duracell alkaline AAA. Results may vary by device or usage pattern. (IEC’s website). For more info: Duracell’s website</li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { authComputed } from "@/store/helpers";
import HotSpot from '@/components/HotSpot.vue';
export default {
    name: 'RichContent',
    components: {
        HotSpot,
    },
    computed: {
      ...authComputed,
    },
    props: {
        data: {
            type: Array,
            default: new Array(),
        },
        keyword: {
            type: String,
            default: '',
        },
    },
}
</script>

<style>

</style>