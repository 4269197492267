import axios from 'axios'
export const report = {
  state: () => ({
    reportData: [],
  }),
  mutations: {
    SET_REPORT_DATA(state, payload) {
      state.reportData = payload;
    },
  },
  actions: {
    exportPDF({ commit }) {
      let arr = this.state.initialCaratSettingValue.settings;
      let expectedValue = {};
      for (let i=0; i < arr.length ; ++i) {
        expectedValue[arr[i].key] = arr[i].toggle_value == null ? false : (arr[i].toggle_value == "1" ? true : false);
      }
      var abc = { ...this.state.carats, ...expectedValue };
      delete abc.isOpenTab;
      delete abc.eSellOutToday;
      delete abc.growthIndex;
      delete abc.sellOutForcast;
      delete abc.increRevenues;
      let a = {
        user_settings: {
          ...this.state.caratshome,
          eSellOutToday: this.state.carats.eSellOutToday,
        },
        carats: {
          ...abc,
        },
      };
      return axios
      .post(axios.baseCalcURL + '/calc/export-user-carats', a)
      .then((res) => {
        // const file = new Blob( [res.data], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        commit("SET_REPORT_DATA", res.data);
        return res
      })
    }
  }
};