<template>
  <li class="carats simulation">
    <div class="carats_head" ref="simhead" >
      <div class="carats_head_icon">
        <span>{{ $store.state.initialCaratSettingValue.selectedStore.name }}</span> &nbsp; 
        | {{ $store.state.initialCaratSettingValue.selectedSimulation.name }}
      </div>
      <div class="icons_list">
        <div class="confirm-modal" v-if="saveModal">
            <p>Are you sure want to Save ?</p>
            <div class="button_list">
              <button class="submit_btn" @click="saveModal = false">Cancel</button>
              <button class="submit_btn" @click="updateStoreSimulations(); saveModal = false;">Save</button>
            </div>
        </div>
        <div class="confirm-modal" v-if="resetModal">
            <p>Are you sure want to Reset ?</p>
            <div class="button_list">
              <button class="submit_btn" @click="resetModal = false">Cancel</button>
              <button class="submit_btn" @click="resetSimulations(); resetModal = false;">Reset</button>
            </div>
        </div>
        <ul>
          <li @click="resetModal= true">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="13.988" viewBox="0 0 13.971 13.988">
              <path id="Icon_open-reload" data-name="Icon open-reload" d="M6.994,0A6.994,6.994,0,1,0,11.96,11.96L10.7,10.7A5.249,5.249,0,1,1,6.977,1.749,5.087,5.087,0,0,1,10.631,3.34L8.725,5.246h5.246V0L11.89,2.081A6.955,6.955,0,0,0,6.977,0Z" fill="#c3c3c3"/>
            </svg>
            <span>Reset</span> </li>
          <li @click="clicked"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="17.252" height="13.988" viewBox="0 0 17.252 13.988">
              <path id="Icon_material-format-list-bulleted" data-name="Icon material-format-list-bulleted" d="M5.149,12.345a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,5.149,12.345Zm0-5.6a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,5.149,6.75Zm0,11.191a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,5.149,17.941Zm2.8,2.331H21V18.407H7.946Zm0-5.6H21V12.812H7.946Zm0-7.46V9.081H21V7.216Z" transform="translate(-3.75 -6.75)" fill="#c3c3c3"/>
            </svg>
            <span>List</span> </li>
          <li @click="saveModal = true"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="14.994" height="14.994" viewBox="0 0 14.994 14.994">
              <g id="Icon_ionic-ios-save" data-name="Icon ionic-ios-save" transform="translate(-4.5 -4.5)">
                <path fill="#c3c3c3" id="Path_1330" data-name="Path 1330" d="M18.375,4.5H17.75a.313.313,0,0,0-.312.312V7.624c0,.172.3.312.469.312h.469a.313.313,0,0,0,.312-.312V4.812A.313.313,0,0,0,18.375,4.5Z" transform="translate(-5.753)" />
                <path fill="#c3c3c3" id="Path_1331" data-name="Path 1331" d="M18.944,7.081,16.917,5.054A1.889,1.889,0,0,0,15.586,4.5H14.492a.631.631,0,0,0-.625.633V8.256a.627.627,0,0,1-.625.625H8.249a.627.627,0,0,1-.625-.625V5.133A.633.633,0,0,0,7,4.5H5.75A1.253,1.253,0,0,0,4.5,5.75v12.5a1.253,1.253,0,0,0,1.25,1.25h12.5a1.253,1.253,0,0,0,1.25-1.25V8.4A1.874,1.874,0,0,0,18.944,7.081ZM14.574,17.624a.664.664,0,0,1-.7.625H7.616a.664.664,0,0,1-.7-.625V13.875a.664.664,0,0,1,.7-.625h6.263a.664.664,0,0,1,.7.625v3.749Z" transform="translate(0 0)" />              </g>
            </svg>
            <span>Save</span> </li>
          <li @click="modalClose = true; simulationName= '';">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.999" height="15" viewBox="0 0 14.999 15">
              <path id="Union_13" data-name="Union 13" d="M15172.5-8771v-6.5h-6.5v-2h6.5v-6.5h2v6.5h6.5v2h-6.5v6.5Z" transform="translate(-15166.002 8786)" fill="#c3c3c3"/>
            </svg>
            <span>Create</span> </li>
        </ul>
      </div>
    </div>
    <div class="carats_body" ref="simbody">
      <div class="create-form" v-if="modalClose">
        <div class="close-icon" @click="modalClose = false">
          <svg data-v-5c833af0="" xmlns="http://www.w3.org/2000/svg" width="29.648" height="29.648" viewBox="0 0 29.648 29.648" class="hmenu-close-icon"><g data-v-5c833af0="" id="Group_964" data-name="Group 964" transform="translate(-4117.748 15950.588)"><path data-v-5c833af0="" id="Path_361" data-name="Path 361" d="M1881.808-13065.527l27.527,27.527" transform="translate(2237 -2884)" fill="none" stroke="#000" stroke-width="3"></path><path data-v-5c833af0="" id="Path_362" data-name="Path 362" d="M1909.335-13065.527,1881.808-13038" transform="translate(2237 -2884)" fill="none" stroke="#000" stroke-width="3"></path></g></svg>
        </div>
        <div class="field_box">
            <label for="email">Enter Name</label>
            <input type="email" v-model="simulationName" placeholder="Name...">
        </div>
        <button class="submit_btn" @click="createStoreSimulations">{{editMode ? 'Edit' : 'Save'}}</button>
      </div>
      <div class="carats_body_box">
        <ul>
          <li
            v-for="(item, i) in $store.state.initialCaratSettingValue.listOfSimulations"
            :key="'itemSim' + i"
          >
            <div class="list-sim">
              <!-- <div class="text" @click="changeItem(item)"><input type="text" :class="editable ? 'edit-mode': ''" :value="item.name"></div> -->
              <div class="text" @click="changeItem(item)">{{item.name}}</div>
              <div class="icons_list">
                <ul>
                    <li @click="editSimulationName(item)"> Edit <span>Edit</span> </li>
                    <li v-if="$store.state.initialCaratSettingValue.listOfSimulations.length > 1" @click="confirmDeleteItem = item; confirmModal = true"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.039" height="13.987" viewBox="0 0 13.039 13.987">
                        <g id="delete" transform="translate(-17.379)">
                          <g id="Group_2773" data-name="Group 2773" transform="translate(17.379 2.1)">
                            <g id="Group_2772" data-name="Group 2772" transform="translate(0)">
                              <g id="Group_2771" data-name="Group 2771">
                                <path id="Path_1338" data-name="Path 1338" d="M306.139,175.1l-1.1-.04-.237,6.483,1.095.04Z" transform="translate(-296.954 -172.373)" fill="#c3c3c3"/>
                                <rect id="Rectangle_1705" data-name="Rectangle 1705" width="1.096" height="6.483" transform="translate(5.971 2.703)" fill="#c3c3c3"/>
                                <path id="Path_1339" data-name="Path 1339" d="M159.751,181.532l-.237-6.483-1.095.04.237,6.483Z" transform="translate(-154.566 -172.367)" fill="#c3c3c3"/>
                                <path id="Path_1340" data-name="Path 1340" d="M17.379,76.867v1.1h1.142l.906,10.293a.548.548,0,0,0,.546.5h7.834a.548.548,0,0,0,.546-.5l.906-10.293h1.16v-1.1ZM27.3,87.66H20.474l-.853-9.7h8.537Z" transform="translate(-17.379 -76.867)" fill="#c3c3c3"/>
                              </g>
                            </g>
                          </g>
                          <g id="Group_2775" data-name="Group 2775" transform="translate(21.196)">
                            <g id="Group_2774" data-name="Group 2774" transform="translate(0)">
                              <path id="Path_1341" data-name="Path 1341" d="M161.568,0h-3.579a.914.914,0,0,0-.913.913V2.648h1.1V1.1h3.214V2.648h1.1V.913A.914.914,0,0,0,161.568,0Z" transform="translate(-157.076)" fill="#c3c3c3"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span>Delete</span> 
                    </li>
                </ul>
              </div>
              <div class="confirm-modal" v-if="confirmModal">
                <p>Are you sure want to delete ?</p>
                <div class="button_list">
                  <button class="submit_btn" @click="confirmModal = false">Cancel</button>
                  <button class="submit_btn" @click="deleteSimulation(confirmDeleteItem); confirmModal = false;">Delete</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex';
import { find } from "lodash";
export default {
  name: "StoreSimulation",
  mounted() {
    this.getSimulationsList({id: this.$store.state.initialCaratSettingValue.selectedStore.id, type:'INITIAL', idForSimulation:''})
    .then((data)=> {
      this.setSimulationsList(data)
      this.setSelectedSimulations(data && data.length > 0 ? data[0] : { id: null, name: "" })
      this.submitFrontForm();
      // this.listOfSimulations = data;
      // this.selectedItem = data && data.length > 0 ? data[0] : { id: null, name: "" }
    })
  },
  data() {
    return {
      selectedItem: { id: null, name: "" },
      editItem: { id: null, name: "" },
      listOfSimulations: [],
      simulationName: '',
      modalClose: false,
      editMode: false,
      confirmModal: false,
      saveModal: false,
      resetModal: false,
      confirmDeleteItem : null
    };
  },
  methods: {
    ...mapActions(
      [
        'getSimulationsList', 
        'createSimulationsSettings', 
        'getSimulationsSettings', 
        'deleteSimulationsSettings',
        'setSimulationsList',
        'setSelectedSimulations',
        'submitFrontForm'
      ]
    ),
    changeItem(item) {
      this.getSimulationsSettings(item.id)
      // this.selectedItem = item;
      this.setSelectedSimulations(item).then(()=> {
        this.submitFrontForm();
      })

      this.closeHeight();
    },
    editSimulationName(item) {
      this.editMode=true; 
      this.modalClose = true; 
      this.editItem = item;
      this.simulationName = item.name;
    },
    resetSimulations() {
      this.getSimulationsList(
        {
          id:this.$store.state.initialCaratSettingValue.selectedStore.id, 
          type: 'SELECT', 
          idForSimulation: this.$store.state.initialCaratSettingValue.selectedSimulation.id
        })
    },
    createStoreSimulations() {
      if(this.editMode) {
        const formData = new FormData();
        formData.append('name', this.simulationName);
        formData.append('store_id', this.editItem.store_id);
        formData.append('simulation_id', this.editItem.id);
        // formData.append('user_settings', JSON.stringify( this.$store.state.initialCaratSettingValue.settings));
        this.createSimulationsSettings(formData).then((res)=> {
          this.editMode=false; 
          this.modalClose = false; 
          this.editItem = {};
          this.simulationName = "";

          this.getSimulationsList({id:res.store_id, type: 'SELECT', idForSimulation:res.id})
          .then((data)=> {
            this.setSimulationsList(data)
            this.setSelectedSimulations(find(data, { 'id': res.id }))
            // this.closeHeight();
            this.submitFrontForm();
            // this.listOfSimulations = data;
            // this.selectedItem = find(data, { 'id': res.id });
          })
          
        })
        return
      }
      // name, store_id, user_settings, simulation_id
      const formData = new FormData();
      formData.append('name', this.simulationName);
      formData.append('store_id', this.$store.state.initialCaratSettingValue.selectedStore.id);
      formData.append('user_settings', JSON.stringify( this.$store.state.initialCaratSettingValue.settings));
      formData.append('simulation_id', '');
      this.createSimulationsSettings(formData).then((res)=> {
        this.simulationName = '';
        this.modalClose = false;
        this.getSimulationsList({id:res.store_id, type: 'SELECT', idForSimulation:res.id})
        .then((data)=> {
          this.setSimulationsList(data)
          this.setSelectedSimulations(find(data, { 'id': res.id }))
          this.closeHeight();
          this.submitFrontForm();
          // this.listOfSimulations = data;
          // this.selectedItem = find(data, { 'id': res.id });
        })
      })
    },
    updateStoreSimulations() {
      // name, store_id, user_settings, simulation_id
      const formData = new FormData();
      formData.append('name', this.$store.state.initialCaratSettingValue.selectedSimulation.name);
      formData.append('store_id', this.$store.state.initialCaratSettingValue.selectedStore.id);
      formData.append('simulation_id', this.$store.state.initialCaratSettingValue.selectedSimulation.id);
      formData.append('user_settings', JSON.stringify( this.$store.state.initialCaratSettingValue.settings));
      this.createSimulationsSettings(formData).then(()=> {
        this.submitFrontForm();
      })
    },
    editStoreSimulations() {
      // name, store_id, user_settings, simulation_id
      
    },
    deleteSimulation(item) {
      // name, store_id, user_settings, simulation_id
      this.deleteSimulationsSettings(item.id).then(()=> {
        this.getSimulationsList({id: this.$store.state.initialCaratSettingValue.selectedStore.id, type:'INITIAL', idForSimulation:''})
        .then((data)=> {
          this.setSimulationsList(data)
          this.setSelectedSimulations(data && data.length > 0 ? data[0] : { id: null, name: "" })
          this.closeHeight();
          // this.submitFrontForm();
        })
      })
    },
    clicked() {
      var hasClass = this.$refs.simhead.classList.contains("show");
      var height = hasClass ? 0 : this.$refs.simbody.scrollHeight;
      this.$refs.simbody.style.height = height + "px";
      if (hasClass) this.$refs.simhead.classList.remove("show");
      else this.$refs.simhead.classList.add("show");
      // this.setCaratsOpen({forKey: this.keyValue, value: !this.carats.isOpenTab[this.keyValue]})
    },
    closeHeight() {
      var hasClass = this.$refs.simhead.classList.contains("show");
      var height = hasClass ? 0 : this.$refs.simbody.scrollHeight;
      this.$refs.simbody.style.height = height + "px";
      this.$refs.simhead.classList.remove("show");
    }
  },
};
</script>

<style></style>
