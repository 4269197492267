<template>
    <footer>
      <back-btn />
      <div class="nav-links">
          <ul>
              <li class="head">Get to Know Us</li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Sustainability</a></li>
              <li><a href="#">Investor Relations</a></li>
              <li><a href="#">Our Devices</a></li>
              <li><a href="#">Our Tours</a></li>
          </ul>
          <ul>
              <li class="head">Electronics</li>
              <li><a href="#">& Supplies</a></li>
              <li><a href="#">Camera & Photo</a></li>
              <li><a href="#">Car & Vehicle Electronics</a></li>
              <li><a href="#">Cell Phones & Accessories</a></li>
              <li><a href="#">Computers & Accessories</a></li>
              <li><a href="#">GPS & Navigation</a></li>
              <li><a href="#">Headphones</a></li>
              <li><a href="#">Home Audio</a></li>
              <li><a href="#">Office Electronics</a></li>
              <li><a href="#">Portable Audio & Video</a></li>
          </ul>
          <ul>
              <li class="head">Digital Content and Devices</li>
              <li><a href="#">Video Content</a></li>
              <li><a href="#">Music</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Apps for Android </a></li>
              <li><a href="#">Best Sellers</a></li>
              <li><a href="#">New Releases </a></li>
              <li><a href="#">Devices</a></li>
          </ul>
          <ul>
              <li class="head">Let us help you</li>
              <li><a href="#">Your Account</a></li>
              <li><a href="#">Your Orders</a></li>
              <li><a href="#">Shipping Rates & Policies</a></li>
              <li><a href="#">Returns & Replacements</a></li>
              <li><a href="#">Manage Your Content and Devices</a></li>
              <li><a href="#">Assistant</a></li>
              <li><a href="#">Help</a></li>
          </ul>
      </div>
      <div class="logo">
          <img :src="$store.state.genralInfo.logo" alt="">
      </div>
      <div class="copyright">
          <ul>
              <li><a href="#">Conditions of Use</a></li>
              <li><a href="#">Privacy Notice</a></li>
          </ul>
      </div>
  </footer>
</template>

<script>
export default {
  name: '',
  components: {},
  computed: {},
  data() {
    return { name: ''}
  },
  mounted() {},
  watch: {
    name() {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
