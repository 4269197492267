<template>
  <div class="row" v-if="getValueFromObj(initialCaratSetting, 'iERate') && getKeyWord(productDetails.product_type) == 'Battery'">
    <div class="col-md-12">
        <div class="pd_review">
            <div class="row">
                <div class="col-lg-3">
                    <h2 class="pd_review_title">
                        Customer reviews
                    </h2>
                    <div class="pd_review_ratings">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>5 out of 5</span>
                    </div>
                    <div class="pd_review_stardetails">
                        <table>
                            <tr>
                                <td class="active">5 Star</td>
                                <td class="strip">
                                    <div class="a-meter">
                                        <div class="a-meter-bar" style="width: 86%;"></div>
                                    </div>
                                </td>
                                <td class="active">86%</td>
                            </tr>
                            <tr>
                                <td>4 Star</td>
                                <td class="strip">
                                    <div class="a-meter">
                                        <div class="a-meter-bar" style="width: 12%;"></div>
                                    </div>
                                </td>
                                <td>12%</td>
                            </tr>
                            <tr>
                                <td>3 Star</td>
                                <td class="strip">
                                    <div class="a-meter">
                                        <div class="a-meter-bar" style="width: 22%;"></div>
                                    </div>
                                </td>
                                <td>22%</td>
                            </tr>
                            <tr>
                                <td>2 Star</td>
                                <td class="strip">
                                    <div class="a-meter">
                                        <div class="a-meter-bar" style="width: 32%;"></div>
                                    </div>
                                </td>
                                <td>32%</td>
                            </tr>
                            <tr>
                                <td>1 Star</td>
                                <td class="strip">
                                    <div class="a-meter">
                                        <div class="a-meter-bar" style="width: 12%;"></div>
                                    </div>
                                </td>
                                <td>12%</td>
                            </tr>
                        </table>
                    </div>

                </div>
                <div class="col-lg-9">
                    <div class="pd_review_list">
                        <div class="pd_review_list_header">
                            <div class="box">
                                <select name="a" id="">
                                    <option value="a">Sort by: Reviews</option>
                                </select>
                                <hot-spot keyValue="custom_review" />
                            </div>
                            <h3 class="pd_review_list_header_title">
                                Top reviews
                            </h3>
                        </div>
                        <div class="pd_review_list_body">
                            <div class="pd_review_list_body_box">
                                <div class="usr">
                                    <div class="usr_img"><img src="@/assets/user/3.jpg" alt=""></div>
                                    <h3 class="usr_name">Oscar</h3>
                                </div>
                                <div class="ratings">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <span>Excellent</span>
                                </div>
                                <div class="review_date">
                                    Reviewed on August 25, 2020
                                    <span>Verified Purchase</span>
                                </div>
                                <div class="desc">
                                    El producto encaja perfectamente con la descripción, ha llegado mucho antes de lo previsto, perfectamente embalado, y el proveedor muy atento.
                                    <br />
                                    Gran profesionalidad, 100% recomendable
                                </div>
                            </div>
                            <div class="pd_review_list_body_box">
                                <div class="usr">
                                    <div class="usr_img"><img src="@/assets/user/1.jpg" alt=""></div>
                                    <h3 class="usr_name">Oscar</h3>
                                </div>
                                <div class="ratings">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <span>Excellent</span>
                                </div>
                                <div class="review_date">
                                    Reviewed on August 25, 2020
                                    <span>Verified Purchase</span>
                                </div>
                                <div class="desc">
                                    El producto encaja perfectamente con la descripción, ha llegado mucho antes de lo previsto, perfectamente embalado, y el proveedor muy atento.
                                    <br />
                                    Gran profesionalidad, 100% recomendable
                                </div>
                            </div>
                            <div class="pd_review_list_body_box">
                                <div class="usr">
                                    <div class="usr_img"><img src="@/assets/user/3.jpg" alt=""></div>
                                    <h3 class="usr_name">Oscar</h3>
                                </div>
                                <div class="ratings">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <span>Excellent</span>
                                </div>
                                <div class="review_date">
                                    Reviewed on August 25, 2020
                                    <span>Verified Purchase</span>
                                </div>
                                <div class="desc">
                                    El producto encaja perfectamente con la descripción, ha llegado mucho antes de lo previsto, perfectamente embalado, y el proveedor muy atento.
                                    <br />
                                    Gran profesionalidad, 100% recomendable
                                </div>
                            </div>
                            <a href="#" class="pd_review_list_body_showall">See all Reviews</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { authComputed } from "@/store/helpers";
import HotSpot from '@/components/HotSpot.vue';
export default {
    name: 'CustomReview',
    components: {
        HotSpot,
    },
    computed: {
        ...authComputed,
    },
    props: {
        keyword: {
            type: String,
            default: '',
        },
    },
}
</script>

<style>

</style>