<template>
  <li class="carats" :style="{borderColor: color}">
    <div class="carats_head" ref="head" @click='clicked'>
      <div class="carats_head_icon">
          <img v-if="typeCheck!== 'without-icon'" :src="img" @error="imageUrlAlt" alt="caratslogo">
          <span>{{name}}</span>
      </div>
      <div class="carats_head_close" :class="typeCheck!== 'without-icon' && typeCheck !== 'main' ? 'number' : typeCheck !== 'main' ? '' : 'main'">
          <div v-if="typeCheck!== 'without-icon' && typeCheck !== 'main'">{{ incremental_per_section[keyValue] == 0 ? '' : '+ $'+ formatToThousand(incremental_per_section[keyValue])}}</div>
          <div v-if="typeCheck == 'main'">Open</div>
      </div>
    </div>
    <div class="carats_body" ref="body">
      <div class="carats_body_box">
        <ul>
          <slot name="bodyData">
            No Data
          </slot>  
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { authComputed, authMethods } from "@/store/helpers";
export default {
  name: "",
  components: {},
  computed: {
    ...authComputed,
  },
  data() {
    return {
      
    };
  },
  props: {
    keyValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    img : {},
    typeCheck : {
      type: String,
      default: 'with'
    }
  },
  mounted() {
    if(this.carats.isOpenTab[this.keyValue]) {
      this.$refs.head.classList.add("show")
      var height = this.$refs.body.scrollHeight;
      this.$refs.body.style.height = height +'px';
      var hasMain = this.$refs.head.children[1].classList.contains("main");
      if ( hasMain ) {
        this.$refs.head.children[1].innerHTML = "Close"
      }
    }
  },
  watch: {
    name() {},
  },
  methods: {
    ...authMethods,
    clicked() {
        
        if(this.keyValue == "main") {
          if(this.carats.isOpenTab[this.keyValue]) {
            document.getElementById("caratOverlay").classList.add('show');
            document.getElementById("caratParents").style.overflowY = "hidden";
          }
          else {
            document.getElementById("caratOverlay").classList.remove('show');
            document.getElementById("caratParents").style.overflowY = "auto";
          }
        }

        var hasClass = this.$refs.head.classList.contains("show");
        var hasMain = this.$refs.head.children[1].classList.contains("main");
        
        var height =  hasClass ? 0 : this.$refs.body.scrollHeight;
        this.$refs.body.style.height = height +'px';
        
        if ( hasMain ) {
            hasClass ? this.$refs.head.children[1].innerHTML = "Open"
                : this.$refs.head.children[1].innerHTML = "Close"
        }
        
        if ( hasClass ) 
            this.$refs.head.classList.remove("show")
        else
            this.$refs.head.classList.add("show")

        this.setCaratsOpen({forKey: this.keyValue, value: !this.carats.isOpenTab[this.keyValue]})
    }
  },
};
</script>

<style scoped lang="scss"></style>
