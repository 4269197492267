import axios from 'axios'
import { find, remove } from 'lodash'
export const cart = {
  state: () => ({
    items: []
  }),
  mutations: {
    SET_CART_ITEMS(state, payload) {
      state.items = payload;
    },
    ADD_ITEMS(state, payload) {
      state.items.push(payload);
    },
  },
  actions: {
    getCartItems({ commit }) {
      // GET <host>/api/checkout/cart
      return axios
      // .get('/descendant-categories?parent_id=1')
      .get('/checkout/cart')
      .then((res) => {
        const items = res.data
        if(items && items.length> 0)
          commit("SET_CART_ITEMS", items);
      })
      
    },
    addCartProduct({ commit, state }, value) {
      var items = state.items;
      if(items && items.length > 0) {
        items.push(value);
      }
      else {
        items = value
      }
      
      commit("SET_CART_ITEMS", items);
    },
    removeCartItem({ commit, state }, id) {
      var items = state.items;
      remove(items, find(state.items, {id : id}));
      commit("SET_CART_ITEMS", items);
    },
    changeCartItemQty({ commit, state }, {id, qty}) {
      var items = state.items;
      var item = find(state.items, {id : id});
      Object.assign(item, {qty: qty});
      commit("SET_CART_ITEMS", items);
    },
    addCartItems({ commit, state }, data) {
      if(!find(state.items, {id : data.id}))
        commit('ADD_ITEMS', data)
      // return axios
      //   // .get('/descendant-categories?parent_id=1')
      //   .post(`/checkout/cart/add/${data.id}`)
      //   .then((res) => {
      //     if(res.data.items && res.data.items.length > 0){
      //       commit('ADD_ITEMS', res.data.items[0].product)
      //     }
      //   })
    },
  },
  getters: {
    cartItems( state ) {
      return state.items
    },
    totalCartValue( state ) {
      var tempPrice = 0;
      for (let index = 0; index < state.items.length; index++) {
        const element = state.items[index];
        tempPrice = parseFloat(element.price) + parseFloat(tempPrice)
      }
      return parseFloat(tempPrice).toFixed(2)
    }
  },
};
