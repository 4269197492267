<template>
  <li>
    <div class="checkbox">
      <div class="text">{{name}}</div>
      <label class="switch">
        <input
          type="checkbox"
          @change="changeCheckbox()"
          :checked="getCheckedStatus"
        />
        <span class="slider round"></span>
      </label>
    </div>
  </li>
</template>

<script>
import { authComputed, authMethods } from "@/store/helpers";
import { find } from "lodash";
export default {
  name: "",
  components: {},
  computed: {
    ...authComputed,
    getCheckedStatus() {
      let initialCaratSetting  = this.$store.state.initialCaratSettingValue.settings;
      let obj = find(initialCaratSetting, { 'key': this.keyValue })
      return obj.toggle_value == null ? false : (obj.toggle_value == "1" ? true : false);
    },
  },
  data() {
    return {};
  },
  props: {
    keyValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  watch: {
    name() {},
  },
  methods: {
    ...authMethods,
    changeCheckbox() {
      // let initialCaratSetting  = this.$store.state.initialCaratSettingValue.settings;
      // let obj = find(initialCaratSetting, { 'key': this.keyValue })
      // // if (obj && obj.toggle_value !== null && obj.toggle_value == 0) return true;
      // // return false
      // obj.toggle_value = !obj.toggle_value
      this.setCaratsSettings({ forKey: this.keyValue, value: true, toKey: 'toggle_value' })
      this.setPrevState({forKey: this.keyValue, value: true, toKey: 'toggle_value'})


      // this.setCarats({forKey: this.keyValue, value: !this.carats[this.keyValue]})
      this.submitFrontForm();
    },
    
  },
};
</script>
