<template>
  <section class="shipping">
      <div class="shipping_box">
            <h2 class="heading">Select a shipping address</h2>
            <p class="desc">
                Is the address you’d like to use displayed below? If so, click the corresponding “Deliver to this address” button. Or you can enter a new shipping address. 
            </p>
            <div class="address_list">
                <div class="address">
                    <h3 class="address_name">Maria Doe</h3>
                    <p class="address_desc">
                        1160 Random Street <br />
                        London, England <br />
                        United Kingdom <br />
                        Phone: 0038265255348 
                    </p>
                    <button class="address_deliver_btn">Deliver to this address</button>
                    <div class="address_btns">
                        <button>Edit</button>
                        <button>Remove</button>
                    </div>
                </div>
            </div>
            <div class="add_address">
                <h3 class="heading">Add a new address</h3>
                <div class="field">
                    <label for="country">Country / Region</label>
                    <select name="" id="">
                        <option value="uk">United Kingdom</option>
                        <option value="usa">USA</option>
                        <option value="uk">USA</option>
                    </select>
                </div>
                <div class="field">
                    <label for="country">Full name (First and Last name)</label>
                    <input type="text" placeholder="Maria Doe">
                </div>
                <div class="field">
                    <label for="country">Address line 1</label>
                    <input type="text" placeholder="Random street 1160">
                </div>
                <div class="field">
                    <label for="country">Address line 2</label>
                    <input type="text" placeholder="1160 Random street">
                </div>
                <div class="field">
                    <label for="country">City</label>
                    <input type="text" placeholder="London">
                </div>
                <div class="field">
                    <label for="country">State / Province / Region</label>
                    <input type="text" placeholder="">
                </div>
                <div class="field">
                    <label for="country">Zip Code</label>
                    <input type="text" placeholder="">
                </div>
                <div class="field">
                    <label for="country">Phone number</label>
                    <input type="text" placeholder="">
                </div>
                <div class="footer">
                    <button class="checkout_btn" @click="showModal">Place the Order</button>
                    <button class="checkout_btn">Continue shopping</button>
                </div>
            </div>
      </div>
      <div class="thankyou" v-if="modalShow">
          <div class="thankyou_box">
              <div class="close_icon" @click="closeModal"><img src="../assets/modal_close.svg" alt=""></div>
              <h3 class="title">Thank You!</h3>
              <p>Your order has been sent successfully.</p>
              <img src="../assets/thankyou.svg" alt="">
          </div>
      </div>
  </section>
</template>

<script>
export default {
    data() {
        return {
            modalShow: false
        }
    },
    methods: {
        showModal(){
            document.documentElement.scrollTop = 0
            document.body.classList.add("modalopen")
            this.modalShow = true;
        },
        closeModal(){
            document.documentElement.scrollTop = 0
            document.body.classList.remove("modalopen")
            this.modalShow = false;
        }
    }

}
</script>

<style>

</style>