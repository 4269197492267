<template>
    <div>
        <div class="pd_content_style no-active" v-if="getKeyWord(productDetails.product_type) == 'Battery' && getValueFromObj(initialCaratSetting, 'iEProductVariation')">
            <p class="pd_content_style_heading">Size Name (Cell Size): <span> {{itemTitle}} </span> </p>
            <div class="pd_content_style_checkbox">
                <label class="c_radio" v-if="false">
                    <span v-if="carats.productVariationStyle == 'Size' || carats.productVariationStyle == 'Size & Style'">Pack of {{ productDetails.pack_count }}</span>
                    <img 
                        :src="productDetails.primary_image"
                        @error="imageUrlAlt" 
                        alt="" 
                        v-if="carats.productVariationStyle !== 'Size' && carats.productVariationStyle !== 'Size & Style'"
                    >
                    <input type="radio" :checked="productDetails.id == $route.params.id ? 'checked': ''" name="radio_cz" @change="$router.push(`/product-detail/${item.id}`)">
                    <span class="checkmark"></span>
                </label>
                <label 
                    class="c_radio" 
                    v-for="(item, i) in relatedProducts"
                    :key="i" 
                    @mouseenter="changeEvent(item.mobile_first_image); itemTitle=item.name"
                    @mouseleave="changeEvent(productDetails.mobile_first_image); itemTitle=productDetails.name"
                >
                    <span v-if="carats.productVariationStyle == 'Size' || carats.productVariationStyle == 'Size & Style'">Pack of {{item.pack_count}} ({{item.battery_format}})</span>
                    <img 
                        :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ?  item.mobile_first_image ? item.mobile_first_image : '../assets/basic.png' : item.primary_image ? item.primary_image : '../assets/basic.png'" 
                        @error="imageUrlAlt"
                        alt="" 
                        v-if="carats.productVariationStyle !== 'Size' && carats.productVariationStyle !== 'Size & Style'"
                    >
                    <input type="radio" :checked="item.id == $route.params.id ? 'checked': ''" name="radio_cz" @change="$router.push(`/product-detail/${item.id}`)">
                    <span class="checkmark"></span>
                </label>
                <hot-spot keyValue="size" />
            </div>
        </div>
        <div class="pd_content_style" v-if="getKeyWord(productDetails.product_type) == 'Battery' && getValueFromObj(initialCaratSetting, 'iEProductVariation') && (carats.productVariationStyle == 'Size & Style' || carats.productVariationStyle == 'Size & Style + Thumbnails')">
            <p class="pd_content_style_heading">Style Name: <span></span> </p>
            <div class="pd_content_style_checkbox">
                <!-- <label class="c_radio" @click="filterRelatedProduct('all')">All
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                </label> -->
                <label class="c_radio" @click="filterRelatedProduct(item)" v-for="(item, i) in this.$store.getters.getProductDetailStyle" :key="i">{{item}}
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                </label>
                <hot-spot keyValue="style" />
            </div>
            <p class="pd_content_style_note">SKU: {{ productDetails.sku }}</p>
        </div>

        <div class="pd_content_style" v-if="productDetails.product_type == 'Bundle' && getValueFromObj(initialCaratSetting, 'iEVirtual')">
            <p class="pd_content_style_heading">Style Name: <span>{{itemTitleForBundle}}</span> </p>
            <div class="pd_content_style_checkbox">
                <label class="c_radio">
                    <span>{{productDetails.battery_format}}</span>
                    <input type="radio" :checked="productDetails.id == $route.params.id ? 'checked': ''" name="radio_cz_bundle" @change="$router.push(`/product-detail/${item.id}`)">
                    <span class="checkmark"></span>
                </label>
                <label 
                    class="c_radio" 
                    v-for="(item, i) in relatedProducts"
                    :key="i" 
                    @mouseenter="changeEvent(item.primary_image); itemTitleForBundle=item.battery_format"
                    @mouseleave="changeEvent(productDetails.primary_image); itemTitleForBundle=productDetails.battery_format"
                >
                    <span>{{item.battery_format}}</span>
                    <input type="radio" :checked="item.id == $route.params.id ? 'checked': ''" name="radio_cz_bundle" @change="$router.push(`/product-detail/${item.id}`)">
                    <span class="checkmark"></span>
                </label>
                <hot-spot keyValue="style" />
            </div>
            <p class="pd_content_style_note">SKU: {{ productDetails.sku }}</p>
        </div>
    </div>
</template>

<script>
import { authComputed } from "@/store/helpers";
import HotSpot from '@/components/HotSpot.vue';
import {filter} from 'lodash'
export default {
    name: 'ProdctSizeAndStyle',
    components: {
        HotSpot,
    },
    computed: {
        ...authComputed,
    },
    data() {
        return { 
            relatedProducts: [],
            itemTitle: 'Duracell Optimum AAA Alkaline Batteries [Pack of 12] ',
            itemTitleForBundle: ''
        }
    },
    props: {
        changeEvent: {
            type: Function
        }
    },
    watch: {
        productDetails() {
            this.relatedProducts = this.productDetails.related_products
            this.itemTitle = this.productDetails.name
            this.itemTitleForBundle = this.productDetails.battery_format
        },
    },
    methods: {
        filterRelatedProduct( val ) {
            if(val == "all") {
                this.relatedProducts = this.productDetails.related_products
            }
            else {
                this.relatedProducts = filter(this.productDetails.related_products, function(o) { 
                    if(o.battery_format == val) 
                        return o;
                });
            }
        },
    }
}
</script>

<style>

</style>