<template>
    <main class="search-page search" v-if="!$store.state.showLoader">
        <div class="search_top">
            <p class="search_top_head" v-if="$store.state.storeType == 'Pure Player'">{{products.meta.from}}-{{products.meta.to}} of {{products.meta.total}} <span v-if="$route.query.search">results for “{{$route.query.search}}”</span></p>
            <div class="search_top_filter" v-if="false">
                <select name="a" id="">
                    <option value="a">Sort by: Most relevant</option>
                </select>
            </div>
        </div>
        <div class="content-parent">
            <section class="main-content">
                <section class="banner" v-if="getValueFromObj(initialCaratSetting, 'iEBanner')" :style="{ backgroundImage : `url(${$store.state.keywordBanner !== '' ? $store.state.keywordBanner : $store.state.selectedCategory.category_image})`}">
                    <hot-spot classValue="search-banner" keyValue="search_banner"/>
                </section>
                <p class="search_top_head">Favourite / Previous Orders</p>
                <div class="mobile_view" v-if="false">
                    <a href="#" class="mobile_view_btn">
                        <div class="mobile_view_btn_icon">
                            <img src="../assets/smartphone.svg" alt="">
                        </div>
                        <p class="mobile_view_btn_text">Mobile View</p>
                    </a>
                </div>
                <section class="product" v-if="true">
                    <div class="container-fluid">
                        <div class="row">
                            <template>
                                <div class="col-lg-3" v-for="(item, i) in normalProd.slice(0, 2)" :key="'no1'+i">
                                    <div class="product_box">
                                        <div class="product_box_img">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                            </router-link>
                                        </div>
                                        <div class="product_box_content">
                                            <div class="product_box_content_tag">
                                                <!-- Sponsored -->
                                            </div>
                                            <h2 class="product_box_content_title">
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                </router-link>
                                                <hot-spot classValue="main-title" keyValue="optimized_title" v-if="getValueFromObj(initialCaratSetting, 'iEOptimized') && i==0"/>
                                            </h2>
                                            <h3 class="product_box_content_price" >
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                            </h3>
                                            <button class="brc_btn" >Add</button>
                                            <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="'norat'+i"></i>
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ item.custom_review }} reviews
                                                </router-link>
                                                <hot-spot keyValue="custom_review"  v-if="i==0"/>
                                            </div>
                                            <h3 class="product_box_content_price" v-if="$store.state.storeType == 'Pure Player'">
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3" v-if="favProducts.data.length > 0">
                                    <div class="product_box">
                                        <div class="product_box_img">
                                            <router-link :to="`/product-detail/${favProducts.data[0].id}`" >
                                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? favProducts.data[0].mobile_first_image : favProducts.data[0].primary_image" @error="imageUrlAlt" alt="">
                                            </router-link>
                                        </div>
                                        <div class="product_box_content">
                                            <div class="product_box_content_tag">
                                                <!-- Sponsored -->
                                            </div>
                                            <h2 class="product_box_content_title">
                                                <router-link :to="`/product-detail/${favProducts.data[0].id}`" >
                                                    {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? favProducts.data[0].optimized_title : favProducts.data[0].name }}
                                                </router-link>
                                            </h2>
                                            <h3 class="product_box_content_price" >
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ favProducts.data[0].formated_price.substring(1)}}
                                            </h3>
                                            <button class="brc_btn" @click="addCartItems(favProducts.data[0]); showHideMiniCart();" >Add</button>
                                            <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                <i class="fa fa-star" v-for="(items, i) in favProducts.data[0].custom_rating ? parseInt(favProducts.data[0].custom_rating): 5" :key="'norat'+i"></i>
                                                <router-link :to="`/product-detail/${favProducts.data[0].id}`" >
                                                    {{ parseInt(favProducts.data[0].custom_review) }} reviews
                                                </router-link>
                                            </div>
                                            <h3 class="product_box_content_price" v-if="$store.state.storeType == 'Pure Player'">
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{ favProducts.data[0].formated_price.substring(1)}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3" v-for="(item, i) in normalProd.slice(2, normalProd.length)" :key="'no2'+i">
                                    <div class="product_box">
                                        <div class="product_box_img">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                            </router-link>
                                        </div>
                                        <div class="product_box_content">
                                            <div class="product_box_content_tag">
                                                <!-- Sponsored -->
                                            </div>
                                            <h2 class="product_box_content_title">
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                </router-link>
                                                <hot-spot classValue="main-title" keyValue="optimized_title" v-if="getValueFromObj(initialCaratSetting, 'iEOptimized') && i==0"/>
                                            </h2>
                                            <h3 class="product_box_content_price" >
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                                
                                            </h3>
                                            <button class="brc_btn" >Add</button>
                                            <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="'norat'+i"></i>
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ item.custom_review }} reviews
                                                </router-link>
                                                <hot-spot keyValue="custom_review"  v-if="i==0"/>
                                            </div>
                                            <h3 class="product_box_content_price" v-if="$store.state.storeType == 'Pure Player'">
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row" v-if="normalProd.length > 0">
                            <div class="col-lg-12">
                                <div class="navig">
                                    <button class="navig_item prev" :class="products.meta.current_page == 1 ? 'diisable': ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16">
                                            <g id="Group_375" data-name="Group 375" transform="translate(0.553 0.706)">
                                                <path id="Path_296" data-name="Path 296" d="M9415.112-3003h-20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/>
                                                <path id="Path_297" data-name="Path 297" d="M9395.149-3009.812l-2.595,2.374,2.595,2.374" transform="translate(-9392.366 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                        </svg>
                                        Prev
                                    </button>
                                    <button class="navig_item num" :class="item == products.meta.current_page ? 'active': ''" v-for="(item, i) in products.meta.last_page" :key="i">{{item}}</button>
                                    <button class="navig_item next" :class="products.meta.current_page == products.meta.last_page ? 'diisable': ''">Next <svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16"> <g id="Group_375" data-name="Group 375" transform="translate(0 0.706)"> <path id="Path_296" data-name="Path 296" d="M9394.366-3003h20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/> <path id="Path_297" data-name="Path 297" d="M9392.554-3009.812l2.595,2.374-2.595,2.374" transform="translate(-9374.591 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/> </g> </svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </main>
</template>

<script>
import HotSpot from '../components/HotSpot.vue'
import { authComputed, authMethods } from '../store/helpers'

export default {
  name: 'Search',
  components: { HotSpot },
  computed: {
      ...authComputed,
      normalProd(){
          return [
              {
                mobile_first_image : require('../assets/fav/1.png'),
                primary_image : require('../assets/fav/1.png'),
                optimized_title : 'Sainsbury’s Crispy Triple Cooked Chips, Taste the Difference 400g (Serves 2)',
                name : 'Sainsbury’s Crispy Triple Cooked Chips, Taste the Difference 400g (Serves 2)',
                formated_price : '7.99',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/2.png'),
                primary_image : require('../assets/fav/2.png'),
                optimized_title : 'Sainsbury’s Stonebaked Pepperoni Hand Stretched Pizza 270g',
                name : 'Sainsbury’s Stonebaked Pepperoni Hand Stretched Pizza 270g',
                formated_price : '1.99',
                custom_rating : '5',
                custom_review : '1258',
              },
            //   {
            //     mobile_first_image : require('../assets/fav/3.png'),
            //     primary_image : require('../assets/fav/3.png'),
            //     optimized_title : 'Duracell Plus Alkaline AA Batteries, pack of 12',
            //     name : 'Duracell Plus Alkaline AA Batteries, pack of 12',
            //     formated_price : '24.99',
            //     custom_rating : '5',
            //     custom_review : '1258',
            //   },
              {
                mobile_first_image : require('../assets/fav/3.png'),
                primary_image : require('../assets/fav/3.png'),
                optimized_title : 'Rubiks Cube',
                name : 'Rubiks Cube',
                formated_price : '10.00',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/4.png'),
                primary_image : require('../assets/fav/4.png'),
                optimized_title : 'Moët & Chandon Brut Imperial 75cl',
                name : 'Moët & Chandon Brut Imperial 75cl',
                formated_price : '1.55',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/5.png'),
                primary_image : require('../assets/fav/5.png'),
                optimized_title : 'Jus-Rol Puff Pastry Ready Rolled Sheet 320g',
                name : 'Jus-Rol Puff Pastry Ready Rolled Sheet 320g',
                formated_price : '3.49',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/6.png'),
                primary_image : require('../assets/fav/6.png'),
                optimized_title : 'Sainsbury’s St Clement’s Cheesecake Slices, Taste the Difference 2x96g',
                name : 'Sainsbury’s St Clement’s Cheesecake Slices, Taste the Difference 2x96g',
                formated_price : '7.49',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/7.png'),
                primary_image : require('../assets/fav/7.png'),
                optimized_title : 'Huggies Little Swimmers Swim Nappies, Nappies Size 5 & 6, 11 Pants',
                name : 'Huggies Little Swimmers Swim Nappies, Nappies Size 5 & 6, 11 Pants',
                formated_price : '8.00',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/8.png'),
                primary_image : require('../assets/fav/8.png'),
                optimized_title : 'Sainsbury’s Ricotta Cheese 250g',
                name : 'Sainsbury’s Ricotta Cheese 250g',
                formated_price : '1.20',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/9.png'),
                primary_image : require('../assets/fav/9.png'),
                optimized_title : 'Wild Purple Case & Coconut Dreams Deo Refill- Starter Pack',
                name : 'Wild Purple Case & Coconut Dreams Deo Refill- Starter Pack',
                formated_price : '30.00',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/10.png'),
                primary_image : require('../assets/fav/10.png'),
                optimized_title : 'Nutribullet Starter Kit',
                name : 'Nutribullet Starter Kit',
                formated_price : '57.99',
                custom_rating : '5',
                custom_review : '1258',
              },
              {
                mobile_first_image : require('../assets/fav/11.png'),
                primary_image : require('../assets/fav/11.png'),
                optimized_title : 'Branston Baked Beans 4x410g',
                name : 'Branston Baked Beans 4x410g',
                formated_price : '1.99',
                custom_rating : '5',
                custom_review : '1258',
              },
          ]
      }
  },
  data() {
    return {
      name: '',
      isLoading: false,
    }
  },
  mounted() {
      this.getFavPageProduct();

  },
  watch: {
    name() {}
  },
  methods: {
      ...authMethods
  }
}
</script>

<style scoped lang="scss">
</style>
