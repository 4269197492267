<template>
  <carat-selection-component />
</template>

<script>
import CaratSelectionComponent from '../components/CaratSelectionComponent';
export default {
  name: "",
  components: {CaratSelectionComponent},
};
</script>