<template>
    <main class="search-page search">
        <div class="search_top">
            <p class="search_top_head">1-32 of 124 results for <span>“batteries”</span></p>
            <div class="search_top_filter">
                <select name="a" id="">
                    <option value="a">Sort by: Most relevant</option>
                </select>
                <select name="a" id="">
                    <option value="a">Sort by: Most relevant</option>
                </select>
            </div>
        </div>
        <div class="content-parent">
            <section class="filter">
                <ul class="filter_box">
                    <li class="head">Deals</li>
                    <li class="checkbox">
                        <input type="checkbox" name="a" id="">
                        <span>Today’s Deals </span>
                    </li>
                </ul>
                <ul class="filter_box">
                    <li class="head">Categories </li>
                    <li class="normal">
                        <div class="collapseed">
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                        </div>
                        <a href="#" class="collapse_btn">
                            <span class="show_all show">See All 20 Categories</span>
                            <span class="show_samll ">See fewer Categories</span>
                        </a>
                    </li>
                </ul>
                <ul class="filter_box">
                    <li class="head">Sub-categories </li>
                    <li class="normal">
                        <div class="collapseed">
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                        </div>
                        <a href="#" class="collapse_btn">
                            <span class="show_all show">See All 14 Categories</span>
                            <span class="show_samll ">See fewer Categories</span>
                        </a>
                    </li>
                </ul>
            </section>
            <section class="main-content">
                <div class="mobile_view">
                    <a href="#" class="mobile_view_btn">
                        <div class="mobile_view_btn_icon">
                            <img src="../assets/smartphone.svg" alt="">
                        </div>
                        <p class="mobile_view_btn_text">Mobile View</p>
                    </a>   
                </div>
                <section class="cell_header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 col-xl-4 d-flex align-center">
                                <!-- <section class="bunny">
                                    <img src="../assets/bunny.png" alt="">
                                    <div class="detail">
                                        <h2 class="title">
                                            Duracell AA, <br />Batteries That Deliver
                                        </h2>
                                        <a href="#">Shop Duracell ></a>
                                    </div>
                                </section> -->
                                <section class="recommended">
                                    <h2 class="recommended_head">Recommededd article</h2>
                                    <p class="recommended_subhead">Written by <a href="#">Someone</a></p>
                                    <h3 class="recommended_heading">Long lasting batteries for All your Electronics</h3>
                                    <h4 class="recommended_date">May 7, 2020 - 3 Recommendation</h4>
                                    <p class="recommended_desc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</p>
                                    <a href="#" class="recommended_link">Read full article</a>
                                </section>
                            </div>
                            <div class="col-lg-12 col-xl-8">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="dura_product">
                                            <div class="dura_product_img">
                                                <img src="../assets/cell/1.png" alt="">
                                            </div>
                                            <h2 class="dura_product_head">Duracell Ultra AA Alkaline
                                                Batteries [Pack of 4…
                                            </h2>
                                            <div class="dura_product_ratings">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <a href="#">15,247 reviews</a>
                                            </div>
                                            <h3 class="dura_product_price">24.99€</h3>
                                            <P class="dura_iEProductDesc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</P>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="dura_product">
                                            <div class="dura_product_img">
                                                <img src="../assets/cell/2.png" alt="">
                                            </div>
                                            <h2 class="dura_product_head">Duracell Ultra AA Alkaline
                                                Batteries [Pack of 12]…
                                            </h2>
                                            <div class="dura_product_ratings">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <a href="#">15,247 reviews</a>
                                            </div>
                                            <h3 class="dura_product_price">24.99€</h3>
                                            <P class="dura_iEProductDesc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</P>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="dura_product">
                                            <div class="dura_product_img">
                                                <img src="../assets/cell/3.png" alt="">
                                            </div>
                                            <h2 class="dura_product_head">Duracell Ultra AAA Alkaline
                                                Batteries [Pack of 6]…
                                            </h2>
                                            <div class="dura_product_ratings">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <a href="#">15,247 reviews</a>
                                            </div>
                                            <h3 class="dura_product_price">24.99€</h3>
                                            <P class="dura_iEProductDesc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</P>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="product">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="product_box">
                                    <div class="product_box_img">
                                        <img src="../assets/product/1.png" alt="">
                                    </div>
                                    <div class="product_box_content">
                                        <div class="product_box_content_tag">
                                            Sponsored
                                        </div>
                                        <h2 class="product_box_content_title">
                                            Duracell Ultra AAA Alkaline Batteries [Pack of 4], 1.5 V LR03 MN2400
                                        </h2>
                                        <div class="product_box_content_ratings">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <a href="#">15,247 reviews</a>
                                        </div>
                                        <h3 class="product_box_content_price">
                                            24.99€ <span>32.50€</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="product_box">
                                    <div class="product_box_img">
                                        <img src="../assets/product/3.png" alt="">
                                    </div>
                                    <div class="product_box_content">
                                        <div class="product_box_content_tag">
                                            Sponsored
                                        </div>
                                        <h2 class="product_box_content_title">
                                            Duracell Plus AAA Alkaline Batteries [Pack of 8], 1.5 V LR06 MX1500
                                        </h2>
                                        <div class="product_box_content_ratings">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <a href="#">15,247 reviews</a>
                                        </div>
                                        <h3 class="product_box_content_price">
                                            24.99€ <span>32.50€</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="product_box">
                                    <div class="product_box_img">
                                        <img src="../assets/product/2.png" alt="">
                                    </div>
                                    <div class="product_box_content">
                                        <!-- <div class="product_box_content_tag">
                                            Sponsored
                                        </div> -->
                                        <h2 class="product_box_content_title">
                                            Duracell Ultra AA Alkaline Batteries [Pack of 6], 1.5 V LR03 MN2400
                                        </h2>
                                        <div class="product_box_content_ratings">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <a href="#">15,247 reviews</a>
                                        </div>
                                        <h3 class="product_box_content_price">
                                            24.99€ <span>32.50€</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="navig">
                                    <a href="#" class="navig_item prev diisable"><svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16"> <g id="Group_375" data-name="Group 375" transform="translate(0.553 0.706)"> <path id="Path_296" data-name="Path 296" d="M9415.112-3003h-20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/> <path id="Path_297" data-name="Path 297" d="M9395.149-3009.812l-2.595,2.374,2.595,2.374" transform="translate(-9392.366 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/> </g> </svg>Prev</a>
                                    <a href="#" class="navig_item num active">1</a>
                                    <a href="#" class="navig_item num">2</a>
                                    <a href="#" class="navig_item num">3</a>
                                    <a href="#" class="navig_item next">Next <svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16"> <g id="Group_375" data-name="Group 375" transform="translate(0 0.706)"> <path id="Path_296" data-name="Path 296" d="M9394.366-3003h20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/> <path id="Path_297" data-name="Path 297" d="M9392.554-3009.812l2.595,2.374-2.595,2.374" transform="translate(-9374.591 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/> </g> </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </main>
</template>

<script>
export default {
  name: 'Landing',
  components: { },
  computed: {},
  data() {
    return { 
      name: '',
    }
  },
  mounted() {
  },
  watch: {
    name() {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
</style>
