<template>
  <div class="loader">
    <div class="strip"></div>
  </div>
</template>

<script>
export default {
    name: 'LoaderComponent'
};
</script>

<style lang="scss" scoped>
.loader {
    display: block;
    width: 100%;
    overflow: hidden;
    .strip {
      width: 200%;
      height: 4px;
      background: linear-gradient(90deg, #038937 0%, #038937 13%, #EA6402 39%, #287CFF 55%, #D7022B 67%, #CD2D8B 93%, #CD2D8B 100%);
      
       @keyframes loading {
          0% {transform: translateX(-450px);}
          50% {transform: translateX(0px);}
          100% {transform: translateX(-450px);}
      }
       animation: loading 1.5s 0s infinite;
    }
}
</style>