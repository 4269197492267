import axios from 'axios'
export const brandstore = {
  state: () => ({
    brandStoreData: [],
  }),
  mutations: {
    SET_BRANDSTORE_DATA(state, payload) {
      state.brandStoreData = payload;
    },
  },
  actions: {
    getBrandStoreData({ commit }, keyFor) {
      return axios
        .get(`/products?limit=1000&brand_category=${keyFor}`)
        .then((res) => {
          commit('SET_BRANDSTORE_DATA', res.data.data)
        })
    },
  },
};