<template>
  <section class="mc" v-bind:class="[showMiniCart ? 'show' : '']" id="mini-cart">
    <div class="mc_close" @click="showHideMiniCart">
      Close
      <span><img src="../assets/modal_close.svg" alt=""/></span>
    </div>
    <div class="mc_box">
      <h3 class="mc_box_title">Item added to Cart</h3>
      <div class="mc_box_prod">
        <div class="mc_box_prod_box" v-for="(item, i) in $store.getters.cartItems" :key="i">
          <div class="mc_box_prod_box_img">
            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="" />
          </div>
          <div class="mc_box_prod_box_content">
            <router-link class="mc_box_list_box_content_title" :to="`/product-detail/${item.id}`">
              <p>
                {{item.name}}
              </p>
            </router-link>
            <p>{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</p>
          </div>
        </div>
        <div class="mc_box_prod_price">
          <p>Subtotal (1 item):<span>{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{$store.getters.totalCartValue}}</span></p>
        </div>
        <!-- <router-link
          class="mc_box_prod_ck_btn"
          to="/cart"
        > Proceed to Checkout </router-link> -->
        <button class="mc_box_prod_ck_btn" @click="gotoCart">
          Proceed to Checkout
        </button>
      </div>
      <div class="mc_box_list" v-if="(getKeyWord(productDetails.product_type) == 'Device' || getKeyWord(productDetails.product_type) == 'Bundle') && getValueFromObj(initialCaratSetting, 'iELightBox')">
        <h2 class="mc_box_list_title">{{ $store.state.storeType == 'Pure Player' ? 'This devices requires:' : 'Power Up your devices with:'}}<hot-spot keyValue="light_box"/></h2>
        <div class="mc_box_list_box" v-for="(item, i) in productDetails.up_sell_products" :key="i">
          <div class="mc_box_list_box_img">
            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="" />
          </div>
          <div class="mc_box_list_box_content">
            <router-link class="mc_box_list_box_content_title" :to="`/product-detail/${item.id}`">
              {{item.name}}
            </router-link>
            <div class="mc_box_list_box_content_ratings">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <a href="#">{{item.custom_review}} reviews</a>
            </div>
            <p class="mc_box_list_box_content_price">{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</p>
            <button @click="addCartItems(item);" class="mc_box_list_box_content_button">Add to Cart</button>
          </div>
        </div>
      </div>
      <div class="mc_box_manuf" v-if="getValueFromObj(initialCaratSetting, 'iELightBox')">
        <img :src="$store.state.genralInfo.mini_cart_banner" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import { authComputed, authMethods } from "../store/helpers";
import HotSpot from './HotSpot.vue';

export default {
  name: "",
  components: {
    HotSpot
  },
  computed: {
    ...authComputed,
  },
  data() {
    return {
      name: "",
    };
  },
  mounted() {
  },
  watch: {
    name() {},
  },
  methods: {
    ...authMethods,
    gotoCart() {
      this.showHideMiniCart()
      this.$router.push({ name: 'Cart' })
    },
    changeCheckbox(key) {
      this.setCarats({ forKey: key, value: !this.carats[key] });
      this.setPrevState({ forKey: key, value: this.carats[key] });
    },
  },
};
</script>

<style scoped lang="scss"></style>
