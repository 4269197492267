export const channel = {
  state: () => ({
    channelList: [],
    selectedChannel : {
      code: '',
      currencies: [
        {
          symbol: '$'
        }
      ]
    }
  }),
  mutations: {
    SET_CHANNELS(state, payload) {
      state.channelList = payload;
    },
    SET_SELECTED_CHANNEL(state, payload) {
      state.selectedChannel = payload;
    },
  },
  actions: {
    setChannels({ commit }, value) {
      commit('SET_CHANNELS', value)
      commit('SET_SELECTED_CHANNEL', value.length > 0 ? value[0] : { code : ''})
    },
    setSelectedChannel({ commit }, keyValue) {
      commit('SET_SELECTED_CHANNEL', keyValue)
    },
  },
};