<template>
    <main>
        <section class="login">
            <div class="logo">
                <img src="../assets/logo_black.svg" alt="">
            </div>
            <div class="login_box">
                <div class="header_checkbox">
                    <label class="c_checkbox">
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark">{{ 'Pure Player' }}</span>
                    </label>
                    <label class="c_checkbox">
                        <input type="radio" name="radio">
                        <span class="checkmark">Brick & Click</span>
                    </label>
                </div>
                <div class="header">
                    <h1 class="head">Register to Duracell Perfect e-store</h1>
                    <p class="subtitle hide">Enter your email address and we will send you a password reset link.</p>
                </div>
                <form action="#">
                    <div class="field">
                        <!-- <div class="field_box active error"> -->
                        <div class="field_box">
                            <label for="email">Email Address</label>
                            <input v-model="email" type="email" placeholder="someone@gmail.com">
                        </div>
                        <!-- <p class="error_text show">Email fail! Please type in your correct email address</p> -->
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <div class="field">
                        <div class="field_box">
                            <label for="fname">First Name</label>
                            <input v-model="fname" type="text" placeholder="john">
                        </div>
                        <p class="error_text"></p>
                    </div>
                    <div class="field">
                        <div class="field_box">
                            <label for="lname">Last Name</label>
                            <input v-model="lname" type="text" placeholder="deo">
                        </div>
                        <p class="error_text"></p>
                    </div>
                    <div class="field">
                        <div class="field_box">
                            <label for="email">Password</label>
                            <input v-model="password" type="password" placeholder="**********">
                        </div>
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <div class="field">
                        <div class="field_box">
                            <label for="c_pass">Confirm Password</label>
                            <input v-model="c_password" type="password" placeholder="**********">
                        </div>
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <a class="submit_btn" @click="registerBtnClick">Register</a>
                    <router-link class="forgot_link" to="/forgot"> Forgot password? </router-link>
                    <a href="#" class="back_btn hide"><span></span> Back to Sign In</a>
                </form>
            </div>
        </section>
    </main>
</template>

<script>
import $ from 'jquery';
export default {
  name: '',
  components: {},
  computed: {},
  data() {
    return {
        email: '',
        fname: '',
        lname: '',
        password: '',
        c_password: ''
    }
  },
  mounted() {
    $('.field_box > *')
    .focus(function() {
        $(this).closest('.field_box').addClass('active');
    })
    .blur(function() {
        $(this).closest('.field_box').removeClass('active');
    });
  },
  watch: {
    name() {}
  },
  methods: {
      registerBtnClick() {
          console.log("VIVEK register"
            ,this.email
            ,this.fname
            ,this.lname
            ,this.password
            ,this.c_password
          );
      }
  }
}
</script>

<style scoped lang="scss">

</style>
