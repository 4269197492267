<template>
    <div class="carats-parent" id="caratParents" v-if="true">
        <section class="carats_section">
            <ul v-if="true">
                <CaratSingle
                    name="Carats"
                    color="#D93B9D"
                    :keyValue="'main'"
                    typeCheck="main"
                    :img="require('@/assets/carats/main-carats.svg')" class="main-box">
                    <template v-slot:bodyData>
                        <ul>
                            <li>
                                <div class="item">
                                    <p>E-Sell-Out Today ($)</p>
                                    <div class="input">
                                        <input class="value_input" type="text" v-model="eSellOutToday">
                                        <div class="next_btn" @click="changeInputValue('eSellOutToday')">
                                            <div class="arrow"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <p>growth index</p>
                                    <div class="price green">{{carats.growthIndex}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <p>E-Sell-Out Forecast ($)</p>
                                    <div class="price">{{formatToThousand(carats.sellOutForcast)}}</div>
                                </div>
                                <div class="item">
                                    <p>incremental revenues ($)</p>
                                    <div class="price">{{formatToThousand(carats.increRevenues)}}</div>
                                </div>
                            </li>
                            <li>
                                <p>Perfect eStore progression</p>
                                <div class="slidecontainer">
                                    <input type="range" min="1" max="100" :value="carats.peProg" class="range-slider" id="myRange" disabled>
                                </div>
                            </li>
                            <li>
                                <div class="item checkbox">
                                    <div class="text">Hotspots</div>
                                    <label class="switch">
                                        <input type="checkbox" @change="changeHotspot" :checked="carats.iEHotspot">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="item checkbox">
                                    <div class="text">Perfect e-store</div>
                                    <label class="switch">
                                        <input type="checkbox" :checked="carats.iEStore" @change="changePeStore($event.target.checked)" >
                                        <span class="slider orange round"></span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </template>
                </CaratSingle>
                <StoreSimulation />
                <CaratSingle
                    name="Search"
                    color="#D93B9D"
                    :keyValue="'search'"
                    :img="require('@/assets/carats/pink-carats.svg')"
                >
                    <template v-slot:bodyData>
                        <carat-list keyValue="iESponsoredProduct" name="Sponsored products"/>
                        <!-- <carat-list keyValue="iERescentlyPurchase" name="Recently purchased"/> -->
                        <carat-list keyValue="iEBanner" name="Banners"/>
                        <carat-list keyValue="iERecords" name="Endorsement record"/>
                        <carat-list keyValue="iEVideoSearch" name="Video Sponsored Product"/>
                        <carat-list keyValue="iEFavourite" name="Favourite" v-if="$store.state.storeType !== 'Pure Player'"/>
                    </template>
                </CaratSingle>

                <CaratSingle
                    name="Assortment"
                    color="#ED7001"
                    :keyValue="'assortment'"
                    :img="require('@/assets/carats/yellow-carats.svg')"
                >
                    <template v-slot:bodyData>
                        <carat-list keyValue="iEEfficient" name="Efficient assortment"/>
                        <carat-list keyValue="iEHighlight" name="Highlight portfolio changes" v-if="false"/>
                        <carat-list keyValue="iERetailer" name="Import retailer assortment" v-if="false"/>
                    </template>
                </CaratSingle>

                <CaratSingle
                    name="Content"
                    color="#01943F"
                    :keyValue="'content'"
                    :img="require('@/assets/carats/green-carats.svg')"
                >
                    <template v-slot:bodyData>
                        <carat-list keyValue="iEMobileFirst" name="Mobile First primary image"/>
                        <carat-list keyValue="iEOptimized" name="Optimized Product Title"/>
                        <li>
                            <div class="checkbox">
                                <div class="text">Secondary Images
                                    <div class="text_num">
                                        Number of images
                                        <input type="number" :value="carats.numberOfImages" max="7" min="1" @change="changeValue('numberOfImages', $event.target.value)">
                                    </div>
                                </div>
                                <label class="switch">
                                    <input type="checkbox" @change="changeCheckbox('iESecondaryImages')" :checked="getCheckedStatus('iESecondaryImages')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <carat-list keyValue="iEVideo" name="Videos"/>
                        <li>
                            <div class="checkbox">
                                <div class="text">Bullet points
                                    <div class="text_num">
                                        Number of bullets
                                        <input type="number" :value="carats.numberOfBullets" max="7" min="1" @change="changeValue('numberOfBullets', $event.target.value)">
                                    </div>
                                </div>
                                <label class="switch">
                                    <input type="checkbox" @change="changeCheckbox('iEBulletPoints')" :checked="getCheckedStatus('iEBulletPoints')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <carat-list keyValue="iEProductDesc" name="Product Description"/>
                        <carat-list keyValue="iERichContent" name="Rich Content"/>
                        <li>
                            <div class="checkbox">
                                <div class="text">Product Variations
                                    <div class="text_prod">
                                        <label class="cc_radio">Size
                                            <input type="radio" name="radio" value="Size" :checked="carats.productVariationStyle == 'Size'"  @change="changeValue('productVariationStyle', $event.target.value)">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="cc_radio">Size & Style
                                            <input type="radio" name="radio" value="Size & Style" :checked="carats.productVariationStyle == 'Size & Style'"  @change="changeValue('productVariationStyle', $event.target.value)">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="cc_radio">Size & Style + Thumbnails
                                            <input type="radio" name="radio" value="Size & Style + Thumbnails" :checked="carats.productVariationStyle == 'Size & Style + Thumbnails'" @change="changeValue('productVariationStyle', $event.target.value)">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <label class="switch">
                                    <input type="checkbox" @change="changeCheckbox('iEProductVariation')" :checked="getCheckedStatus('iEProductVariation')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <carat-list keyValue="iEBrandMenu" name="Brand Menu"/>
                    </template>
                </CaratSingle>

                <CaratSingle
                    name="rates & reviews"
                    color="#0086C3"
                    :keyValue="'rates_reviews'"
                    :img="require('@/assets/carats/blue-carats.svg')"
                >
                    <template v-slot:bodyData>
                        <carat-list keyValue="iERate" name="Rates & Reviews"/>
                    </template>
                </CaratSingle>

                <CaratSingle
                    name="ACTIVE X-SELLING"
                    color="#DC0531"
                    :keyValue="'active_x_selling'"
                    :img="require('@/assets/carats/red-carats.svg')"
                >
                    <template v-slot:bodyData>
                        <carat-list keyValue="iELightBox" name="Light box"/>
                        <carat-list keyValue="iEFrequentlyBought" name="Frequently bought together"/>
                        <!-- <carat-list keyValue="iEPremium" name="Premium line rule"/> -->
                        <carat-list keyValue="iEVirtual" name="Virtual bundle"/>
                        <carat-list keyValue="iECheckout" name="Check out Reminder"/>
                    </template>
                </CaratSingle>

                <CaratSingle 
                    name="COMPETITION"
                    color="transparent"
                    :keyValue="'compenstiation'"
                    typeCheck="without-icon">
                    <template v-slot:bodyData>
                        <ul>
                            <li>
                                <div class="checkbox">
                                    <div class="text w-100">
                                        <div class="text_prod">
                                            <div class="group">
                                                <div class="group_inner">
                                                    <label 
                                                        class="cc_checkbox" 
                                                        v-for="(item, i) in $store.state.genralInfo.brands.slice(1, ($store.state.genralInfo.brands.length / 2) + 1)" 
                                                        :key="i"
                                                    >
                                                        {{item}}
                                                        <input type="checkbox" v-model="selectedBrandArray" :value="item" >
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="group_inner">
                                                    <label 
                                                        class="cc_checkbox" 
                                                        v-for="(item, i) in $store.state.genralInfo.brands.slice(($store.state.genralInfo.brands.length / 2) + 1, $store.state.genralInfo.brands.length)" 
                                                        :key="i"
                                                    >
                                                        {{item}}
                                                        <input type="checkbox" v-model="selectedBrandArray" :value="item">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </CaratSingle>
                <CaratSingle 
                    name="CUSTOMER DESIGN"
                    color="transparent"
                    :keyValue="'customerDesign'"
                    typeCheck="without-icon">
                    <template v-slot:bodyData>
                        <ul>
                            <li>
                                <div class="checkbox">
                                    <div class="text w-100">
                                        <div class="text_prod">
                                            <div class="group">
                                                <div class="group_inner">
                                                    <label class="cc_checkbox">Private label
                                                        <input type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="cc_checkbox">Energizer
                                                        <input type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="group_inner">
                                                    <label class="cc_checkbox">EPL
                                                        <input type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="cc_checkbox">Eneloop
                                                        <input type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </CaratSingle>
                <!-- <button class="export_btn" @click="exportPDF"> -->
                <button class="export_btn" @click="routeToReport">
                    GO TO REPORT 
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.756" height="15" viewBox="0 0 10.756 15" v-if="true">
                        <g id="Group_2347" data-name="Group 2347" transform="translate(-156.033 -1330.5)">
                            <path id="Path_1036" data-name="Path 1036" d="M-16524.5-12114v10.086" transform="translate(16685.908 13445)" fill="none" stroke="#19ac49" stroke-linecap="round" stroke-width="1"/>
                            <path id="Path_1037" data-name="Path 1037" d="M-16505.168-12103.6l4.672,4.671,4.67-4.671" transform="translate(16661.908 13440.597)" fill="none" stroke="#19ac49" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Path_1038" data-name="Path 1038" d="M-16564.26-12102.028h9.342" transform="translate(16721 13447.028)" fill="none" stroke="#19ac49" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </button>
            </ul>
        </section>
        <div class="overlay" id="caratOverlay"></div>
    </div>
</template>

<script>
import { authComputed, authMethods } from '@/store/helpers'
import CaratSingle from './CaratSingle'
import CaratList from './CaratList.vue'
import StoreSimulation from './StoreSimulation.vue'
import { mapActions } from 'vuex'
import { find } from 'lodash'

export default {
  name: '',
  components: { CaratSingle, CaratList, StoreSimulation },
  computed: {
      ...authComputed
  },
  data() {
    return { 
        name: '',
        selectedBrandArray: this.$store.state.selectedBrands,
        eSellOutToday: this.$store.state.carats.eSellOutToday,
        // prevState: {
        //     iESponsoredProduct : this.$store.state.carats.iESponsoredProduct,
        //     iERescentlyPurchase : this.$store.state.carats.iERescentlyPurchase,
        //     iEBanner : this.$store.state.carats.iEBanner,
        //     iERecords : this.$store.state.carats.iERecords,
        //     iEEfficient : this.$store.state.carats.iEEfficient,
        //     iEMobileFirst : this.$store.state.carats.iEMobileFirst,
        //     iEOptimized : this.$store.state.carats.iEOptimized,
        //     iESecondaryImages : this.$store.state.carats.iESecondaryImages,
        //     iEVideo : this.$store.state.carats.iEVideo,
        //     iEBulletPoints : this.$store.state.carats.iEBulletPoints,
        //     iEProductDesc : this.$store.state.carats.iEProductDesc,
        //     iERichContent : this.$store.state.carats.iERichContent,
        //     iEProductVariation : this.$store.state.carats.iEProductVariation,
        //     iEBrandMenu : this.$store.state.carats.iEBrandMenu,
        //     iERate : this.$store.state.carats.iERate,
        //     iELightBox : this.$store.state.carats.iELightBox,
        //     iEVideoSearch : this.$store.state.carats.iEVideoSearch,
        // }
    }
  },
  mounted() {
  },
  watch: {
    selectedBrandArray: function() {
        this.$store.commit('SET_SELECTED_BRANDS', this.selectedBrandArray)
    }
  },
  methods: {
      ...authMethods,
      ...mapActions(['getSimulationsSettings']),
      getCheckedStatus(key) {
        let initialCaratSetting  = this.$store.state.initialCaratSettingValue.settings;
        let obj = find(initialCaratSetting, { 'key': key })
        return obj.toggle_value == null ? false : (obj.toggle_value == "1" ? true : false);
        // return obj.toggle_value
      },
      changeHotspot() {
        this.$store.commit("SET_CARATS", {forKey: 'iEHotspot', value: !this.$store.state.carats.iEHotspot});
      },
      changeCheckbox(key) {
          this.setCaratsSettings({ forKey: key, value: true, toKey: 'toggle_value' })
          this.submitFrontForm();
      },
      routeToReport() {
          this.exportPDF().then(() => {
              this.$router.push({ name: 'Report'})
          })
      },
      changeInputValue(key) {
          this.setCarats({forKey: key, value: parseInt(this.eSellOutToday)})
          this.submitFrontForm();
      },
      changeValue(key, value) {
          this.setCarats({forKey: key, value: value})
          this.setPrevState({forKey: key, value: value})
          this.submitFrontForm();
      },
      changePeStore(value) {
          this.setCarats({forKey: 'iEStore', value: value})
          var enableArray = [
            'iESponsoredProduct',
            'iEBanner',
            'iERecords',
            'iEVideoSearch',
            'iEEfficient',
            'iEMobileFirst',
            'iEOptimized',
            'iESecondaryImages',
            'iEVideo',
            'iEBulletPoints',
            'iEProductDesc',
            'iERichContent',
            'iEProductVariation',
            'iEBrandMenu',
            'iERate',
            'iELightBox',
          ];
          if(value == true) {
              enableArray.forEach((e)=> {
                this.setCaratsSettings({ forKey: e, value: true, toKey: 'all' })
              });
              this.submitFrontForm();
          } else {
              this.getSimulationsSettings(this.$store.state.initialCaratSettingValue.selectedSimulation.id).then(()=> {
                  this.submitFrontForm();
              })
            // for (const [key, value] of Object.entries(this.$store.state.carats.prevState)) {
            //   if(typeof(value) == 'boolean') {
            //       this.setCarats({forKey: key, value: value})
            //   }
            // }
          }

          
      }
  }
}
</script>

<style scoped lang="scss">
.overlay {
    position: absolute;
    top: 5rem;
    left: -110%;
    // width: $caratsWidth - 20;
    width: $caratsWidth;
    height: 100vh;
    background: #fff;
    transition: .3s all ease-out;
    z-index: 9999999;
    &.show {
      left: 0;
    }
  }
</style>
