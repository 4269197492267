<template>
    <main class="search-page search" v-if="!$store.state.showLoader">
        <div class="search_top">
            <p class="search_top_head">{{products.meta.from}}-{{products.meta.to}} of {{products.meta.total}} <span v-if="$route.query.search">results for “{{$route.query.search}}”</span></p>
            <div class="search_top_filter" v-if="false">
                <select name="a" id="">
                    <option value="a">Sort by: Most relevant</option>
                </select>
            </div>
        </div>
        <div class="content-parent">
            <section class="filter">
                <ul class="filter_box">
                    <li class="head">Deals</li>
                    <li class="checkbox">
                        <input type="checkbox" name="a" id="">
                        <span>Today’s Deals </span>
                    </li>
                </ul>
                <ul class="filter_box">
                    <li class="head">Categories </li>
                    <li class="normal">
                        <div class="collapseed">
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                        </div>
                        <a href="#" class="collapse_btn">
                            <span class="show_all show">See All 20 Categories</span>
                            <span class="show_samll ">See fewer Categories</span>
                        </a>
                    </li>
                </ul>
                <ul class="filter_box">
                    <li class="head">Sub-categories </li>
                    <li class="normal">
                        <div class="collapseed">
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                            <a href="#">Disposable Household Batteries</a>
                            <a href="#">Camera & Photo</a>
                            <a href="#">Rechargeable Household Batteries</a>
                            <a href="#">Mobile Phone Chargers</a>
                            <a href="#">Household Battery Chargers</a>
                            <a href="#">Household Battery & Charger Sets</a>
                        </div>
                        <a href="#" class="collapse_btn">
                            <span class="show_all show">See All 14 Categories</span>
                            <span class="show_samll ">See fewer Categories</span>
                        </a>
                    </li>
                </ul>
            </section>
            <section class="main-content">
                <section class="banner" v-if="getValueFromObj(initialCaratSetting, 'iEBanner')" :style="{ backgroundImage : `url(${$store.state.keywordBanner !== '' ? $store.state.keywordBanner : $store.state.selectedCategory.category_image})`}">
                    <hot-spot classValue="search-banner" keyValue="search_banner"/>
                </section>
                <div class="mobile_view" v-if="false">
                    <a href="#" class="mobile_view_btn">
                        <div class="mobile_view_btn_icon">
                            <img src="../assets/smartphone.svg" alt="">
                        </div>
                        <p class="mobile_view_btn_text">Mobile View</p>
                    </a>
                </div>
                <div v-if="getValueFromObj(initialCaratSetting, 'iESponsoredProduct') && $store.getters.sponsredBrandProduct.length > 0">
                    <section class="cell_header">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-xl-4 d-flex align-center">
                                    <section class="bunny">
                                        <img src="../assets/bunny.png" alt="">
                                        <div class="detail">
                                            <h2 class="title" v-if="false">
                                                Duracell AA, <br />Batteries That Deliver
                                            </h2>
                                            <a href="#">Shop Duracell ></a>
                                        </div>
                                    </section>
                                </div>
                                <div class="col-lg-12 col-xl-8">
                                    <div class="row">
                                        <div class="col-lg-4" v-for="(item, i) in $store.getters.sponsredBrandProduct.slice(0,3)" :key="i">
                                            <router-link :to="`/product-detail/${item.id}`"  >
                                                <div class="dura_product">
                                                    <div class="dura_product_img">
                                                        <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                                    </div>
                                                    <h2 class="dura_product_head" >{{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}</h2>
                                                    <div class="dura_product_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                        <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                                        <a href="#">{{ item.custom_review }} reviews</a>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hot-spot classValue="cell-head" keyValue="sponsored_brand"/>
                    </section>
                    <section class="product" v-if="$store.getters.sponsredProduct.length > 0">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="product_box" v-for="(item, i) in $store.getters.sponsredProduct.slice(0,3)" :key="i">
                                        <div class="product_box_img">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                            </router-link>
                                        </div>
                                        <div class="product_box_content">
                                            <div class="product_box_content_tag">
                                                Sponsored <hot-spot classValue="" keyValue="sponsored_product" v-if="i==0"/>
                                            </div>
                                            <h2 class="product_box_content_title">
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                </router-link>
                                                <hot-spot classValue="main-title" keyValue="optimized_title" v-if="getValueFromObj(initialCaratSetting, 'iEOptimized') && i==0"/>
                                            </h2>
                                            <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ item.custom_review }} reviews
                                                </router-link>
                                                <hot-spot keyValue="custom_review"  v-if="i==0"/>
                                            </div>
                                            <h3 class="product_box_content_price">
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section class="cell_header" v-if="false">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 col-xl-6 d-flex align-center">
                               <section class="recommended">
                                    <div class="recommended_img">
                                        <img src="../assets/additional_logo.svg" alt="">
                                    </div>
                                    <div>
                                        <h2 class="recommended_head">Recommededd article</h2>
                                        <p class="recommended_subhead">Written by <a href="#">Someone</a></p>
                                        <h3 class="recommended_heading">Long lasting batteries for All your Electronics</h3>
                                        <h4 class="recommended_date">May 7, 2020 - 3 Recommendation</h4>
                                        <p class="recommended_desc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</p>
                                        <a href="#" class="recommended_link">Read full article</a>
                                    </div>
                                </section>
                            </div>
                            <div class="col-lg-12 col-xl-5" v-for="(item, i) in $store.getters.endorsementProduct" :key="i">
                                <hot-spot classValue="endorsement" keyValue="endorsement"/>
                                <router-link :to="`/product-detail/${item.id}`" style="width: 100%">
                                    <div class="dura_product">
                                        <div class="dura_product_img">
                                            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                        </div>
                                        <h2 class="dura_product_head">
                                            {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                        </h2>
                                        <div class="dura_product_ratings" v-if="false">
                                            <i  class="fa fa-star" v-for="(item, i) in parseInt(item.custom_rating)" :key="i"></i>
                                            <a href="#">{{ item.custom_review }} reviews</a>
                                        </div>
                                        <h3 class="dura_product_price">{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</h3>
                                        <p class="dura_product_desc" v-html="item.short_description"></p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section>
                
                <div class="no-data" v-if="isLoading">
                    No Data available ...
                </div>
                <section class="product" v-if="true">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12" v-for="(item, i) in normalProd.slice(0, 6)" :key="'first' + item.id + i">
                                <div class="product_box" v-if="item.video_on_search !== 'Yes'">
                                    <div class="product_box_img">
                                        <router-link :to="`/product-detail/${item.id}`" >
                                            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                        </router-link>
                                    </div>
                                    <div class="product_box_content">
                                        <div class="product_box_content_tag">
                                            <!-- Sponsored -->
                                        </div>
                                        <h2 class="product_box_content_title">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                            </router-link>
                                            <hot-spot classValue="main-title" keyValue="optimized_title" v-if="getValueFromObj(initialCaratSetting, 'iEOptimized') && i==0"/>
                                        </h2>
                                        <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                            <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                {{ item.custom_review }} reviews
                                            </router-link>
                                            <hot-spot keyValue="custom_review"  v-if="i==0"/>
                                        </div>
                                        <h3 class="product_box_content_price">
                                            {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-for="(item, i) in $store.state.videoProduct.data.slice(0,1)" :key="'video' + item.id + i">
                                <div class="product_box" :class="getValueFromObj(initialCaratSetting, 'iEVideoSearch') ? 'video_type': ''" v-if="item.video_on_search == 'Yes'">
                                    <div class="product_box_img" v-if="!getValueFromObj(initialCaratSetting, 'iEVideoSearch')">
                                        <router-link :to="`/product-detail/${item.id}`" >
                                            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                        </router-link>
                                    </div>
                                    <div class="product_box_content" v-if="!getValueFromObj(initialCaratSetting, 'iEVideoSearch')">
                                        <div class="product_box_content_tag">
                                            <!-- Sponsored -->
                                        </div>
                                        <h2 class="product_box_content_title">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                            </router-link>
                                        </h2>
                                        <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                            <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                {{ item.custom_review }} reviews
                                            </router-link>
                                        </div>
                                        <h3 class="product_box_content_price">
                                            {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                        </h3>
                                    </div>

                                    <div class="row" v-if="getValueFromObj(initialCaratSetting, 'iEVideoSearch')">
                                        <div class="col-lg-12 col-xl-6 d-flex align-center">
                                            <iframe :src="getVideoUrl(item.sponsored_video)+ '?rel=0&amp;autoplay=1&mute=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div class="col-lg-12 col-xl-6">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                <div class="dura_product">
                                                    <div class="dura_product_img">
                                                        <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                                    </div>
                                                    <div>
                                                        <h2 class="dura_product_head">
                                                            {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                        </h2>
                                                        <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                            <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                                            <router-link :to="`/product-detail/${item.id}`" >
                                                                {{ item.custom_review }} reviews
                                                            </router-link>
                                                            <hot-spot keyValue="custom_review"  v-if="i==0"/>
                                                        </div>
                                                        <h3 class="dura_product_price">{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <section class="cell_header" v-if="getValueFromObj(initialCaratSetting, 'iERecords')">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-lg-12 col-xl-6 d-flex align-center">
                                            <section class="recommended">
                                                    <div class="recommended_img">
                                                        <img src="../assets/additional_logo.svg" alt="">
                                                    </div>
                                                    <div>
                                                        <h2 class="recommended_head">Recommededd article</h2>
                                                        <p class="recommended_subhead">Written by <a href="#">Someone</a></p>
                                                        <h3 class="recommended_heading">Long lasting batteries for All your Electronics</h3>
                                                        <h4 class="recommended_date">May 7, 2020 - 3 Recommendation</h4>
                                                        <p class="recommended_desc">Everyone knows the endurance of the Duracell Bunny AAA batteries provide reliable and long-lasting energy to power all your devices with up to 50 Percent more power the Superior Nylon top closure helps prevent leakage and protects all family’s favourite everyday devices such as torch, remote control, clock, toys, Arlo security camera, etc Duralock technology keeps the full energy of your unused Duracell batteries for up to 10 years in their packaging (ambient storage)</p>
                                                        <a href="#" class="recommended_link">Read full article</a>
                                                    </div>
                                                </section>
                                            </div>
                                            <div class="col-lg-12 col-xl-5" v-for="(item, i) in $store.getters.endorsementProduct.slice(0,1)" :key="i">
                                                <hot-spot classValue="endorsement" keyValue="endorsement"/>
                                                <router-link :to="`/product-detail/${item.id}`" style="width: 100%">
                                                    <div class="dura_product">
                                                        <div class="dura_product_img">
                                                            <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                                        </div>
                                                        <h2 class="dura_product_head">
                                                            {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                        </h2>
                                                        <div class="dura_product_ratings" v-if="false">
                                                            <i  class="fa fa-star" v-for="(item, i) in parseInt(item.custom_rating)" :key="i"></i>
                                                            <a href="#">{{ item.custom_review }} reviews</a>
                                                        </div>
                                                        <h3 class="dura_product_price">{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</h3>
                                                        <p class="dura_product_desc" v-html="item.short_description"></p>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <template v-if="normalProd.length > 7">
                                <div class="col-lg-12" v-for="(item, i) in normalProd.slice(6, normalProd.length)" :key="'second' + item.id + i">
                                    <div class="product_box" v-if="item.video_on_search !== 'Yes'">
                                        <div class="product_box_img">
                                            <router-link :to="`/product-detail/${item.id}`" >
                                                <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                            </router-link>
                                        </div>
                                        <div class="product_box_content">
                                            <div class="product_box_content_tag">
                                                <!-- Sponsored -->
                                            </div>
                                            <h2 class="product_box_content_title">
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                                                </router-link>
                                            </h2>
                                            <div class="product_box_content_ratings" v-if="getValueFromObj(initialCaratSetting, 'iERate')">
                                                <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                                                <router-link :to="`/product-detail/${item.id}`" >
                                                    {{ item.custom_review }} reviews
                                                </router-link>
                                            </div>
                                            <h3 class="product_box_content_price">
                                                {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row" v-if="normalProd.length > 0">
                            <div class="col-lg-12">
                                <div class="navig">
                                    <button @click="productNextPrevClick(products.links.prev)" class="navig_item prev" :class="products.meta.current_page == 1 ? 'diisable': ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16">
                                            <g id="Group_375" data-name="Group 375" transform="translate(0.553 0.706)">
                                                <path id="Path_296" data-name="Path 296" d="M9415.112-3003h-20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/>
                                                <path id="Path_297" data-name="Path 297" d="M9395.149-3009.812l-2.595,2.374,2.595,2.374" transform="translate(-9392.366 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                        </svg>
                                        Prev
                                    </button>
                                    <button @click="productNextPrevClick(`${products.meta.path}${getProductListAPIAttr()}&channel=${$store.state.channel.selectedChannel.code}&page=${item}${$route.query.search ? '&search='.$route.query.search : ''}${$route.query.category ? '&category_id=' + $route.query.category : ''}`)" class="navig_item num" :class="item == products.meta.current_page ? 'active': ''" v-for="(item, i) in products.meta.last_page" :key="i">{{item}}</button>
                                    <button @click="productNextPrevClick(products.links.next)" class="navig_item next" :class="products.meta.current_page == products.meta.last_page ? 'diisable': ''">Next <svg xmlns="http://www.w3.org/2000/svg" width="21.299" height="6.16" viewBox="0 0 21.299 6.16"> <g id="Group_375" data-name="Group 375" transform="translate(0 0.706)"> <path id="Path_296" data-name="Path 296" d="M9394.366-3003h20.746" transform="translate(-9394.366 3005.375)" fill="none" stroke="#2b2b2b" stroke-width="1"/> <path id="Path_297" data-name="Path 297" d="M9392.554-3009.812l2.595,2.374-2.595,2.374" transform="translate(-9374.591 3009.812)" fill="none" stroke="#2b2b2b" stroke-linecap="round" stroke-width="1"/> </g> </svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </main>
</template>

<script>
import HotSpot from '../components/HotSpot.vue'
import { authComputed, authMethods } from '../store/helpers'
export default {
  name: 'Search',
  components: { HotSpot },
  computed: {
      ...authComputed,
      normalProd(){
          var a = [];
          if(this.getValueFromObj(this.initialCaratSetting, 'iEEfficient') == false) {
            a = this.$store.getters.normalAllProduct.filter(function(o) {
                if(o.is_efficient_assortment == "Yes")
                    return o;
            });
          }
          else {
              a = this.$store.getters.normalAllProduct
          }
          var filterBySelectedBrand = (val) => {
            return function(element) {
                return val.includes(element.brand);
            } 
          };
          a = a.filter(filterBySelectedBrand(this.$store.state.selectedBrands));
          a.length > 0 ? this.isLoading == true : this.isLoading == false
          return a
      }
  },
  data() {
    return {
      name: '',
      isLoading: false,
    }
  },
  mounted() {
    this.getSonsoredProducts({id : this.$route.query.category, query: this.$route.query.search})
    this.getVideoSearchProduct({id : this.$route.query.category, query: this.$route.query.search})
    this.getProductsAll({id : this.$route.query.category, query: this.$route.query.search})
  },
  watch: {
    name() {}
  },
  methods: {
      ...authMethods,
      productNextPrevClick( link ) {
          this.getProducts(link)
          document.documentElement.scrollTop = 0
      }
  }
}
</script>
