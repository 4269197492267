<template>
    <main>

        <div class="no-data" v-if="$store.state.brandstore.brandStoreData.length == 0">
            No Data available ...
        </div>
        <section class="brand_store">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3" v-for="(item, i) in $store.state.brandstore.brandStoreData" :key="i">
                <div class="brand_store_box">
                  <!-- <router-link :to="`/product-detail/${item.id}`" > -->
                    <div class="dura_product">
                      <div class="dura_product_img">
                        <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                      </div>
                      <h2 class="dura_product_head">
                        <router-link :to="`/product-detail/${item.id}`" >
                            {{ getValueFromObj(initialCaratSetting, 'iEOptimized') ? item.optimized_title : item.name }}
                        </router-link>
                      </h2>
                      <div class="dura_product_ratings">
                        <i class="fa fa-star" v-for="(item, i) in item.custom_rating ? parseInt(item.custom_rating): 5" :key="i"></i>
                        <router-link :to="`/product-detail/${item.id}`" >{{ item.custom_review }} reviews</router-link>
                      </div>
                      <h3 class="product_box_content_price"> {{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}} <span>{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{item.formated_price.substring(1)}}</span></h3>
                      <a class="cart_btn" @click="addCartItems(item); onlyShowMiniCart();">
                        <img src="../assets/add_cart.svg" alt="">
                        <span>Add to Cart</span>
                      </a>
                    </div>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </section>
    </main>
</template>

<script>
import { authComputed, authMethods } from "@/store/helpers";


export default {
  name: 'BrandStore',
  components: {
  },
  computed: {
      ...authComputed,
  },
  data() {
    return { 
      name: '',
    }
  },
  mounted() {
    const {id} = this.$route.params;
    this.getBrandStoreData(id);
  },
  watch: {
    
  },
  methods: {
    ...authMethods
  } 
}
</script>

<style scoped lang="scss">

</style>

