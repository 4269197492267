<template>
    <div>
        <ProductDetailPP v-if="$store.state.storeType == 'Pure Player'"/>
        <ProductDetailBrick v-if="$store.state.storeType !== 'Pure Player'"/>
    </div>
</template>

<script>
import ProductDetailBrick from "./ProductDetailBrick";
import ProductDetailPP from "./ProductDetailPP";
export default {
  name: 'Search',
  components: { 
    ProductDetailBrick,
    ProductDetailPP
  },
  computed: {
      
  },
  data() {
    return {
      name: '',
    }
  },
  mounted() {
  },
  watch: {
    name() {}
  },
  methods: {
      
  }
}
</script>

<style scoped lang="scss">
</style>
