<template>
    <div class="row" v-if="getValueFromObj(initialCaratSetting, 'iEFrequentlyBought') && productDetails.cross_sell_products.length > 0">
        <div class="col-lg-12">
            <div class="pd_frequntly">
                <h3 class="title">Frequently bought together <hot-spot keyValue="frequntly_bought"/></h3>
                <div class="pd_frequntly_items">
                    <ul class="pd_frequntly_items_list">
                        <li class="pd_frequntly_items_pimg">
                            <img v-if="productDetails.mobile_first_image" :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? productDetails.mobile_first_image : productDetails.primary_image"  @error="imageUrlAlt" alt="">
                        </li>
                        <!-- <div> -->
                            <li class="pd_frequntly_items_plus">+</li>
                            <router-link :to="`/product-detail/${item.id}`" v-for="(item, i) in productDetails.cross_sell_products.slice(0,1)" :key="i">
                                <li class="pd_frequntly_items_pimg">
                                    <img :src="getValueFromObj(initialCaratSetting, 'iEMobileFirst') ? item.mobile_first_image : item.primary_image" @error="imageUrlAlt" alt="">
                                </li>
                            </router-link>
                        <!-- </div> -->
                        <li class="pd_frequntly_items_total">
                            <p>Total price: <span>{{ $store.state.channel.selectedChannel.currencies[0].symbol }} {{calculatePrice(productDetails.price, productDetails.cross_sell_products[0].price)}}</span></p>
                            <a @click="addCartItems(productDetails.cross_sell_products[0]); showHideMiniCart();" class="pd_frequntly_items_total_cart_btn">
                                <img src="@/assets/add_cart.svg" alt=""><span>Add to Cart</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="pd_frequntly_items_names" v-if="false">
                        <li>
                            <input type="checkbox" name="item" id=""><span>This item:</span> Monster Jam Authentic Grave Digger RC, 1:24 Scale, 2.4 GHz, for Ages 4 and Up <span class="price">15.96€</span>
                        </li>
                        <li class="active">
                            <input type="checkbox" name="item" id="">Duracell Ultra AAA Alkaline Batteries [Pack of 8], 1.5 V LR03 MN2400 <span class="price">7.00€</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authMethods, authComputed } from "@/store/helpers";
import HotSpot from '@/components/HotSpot.vue';
export default {
    name: 'FrequentlyBought',
    components: {
        HotSpot,
    },
    computed: {
        ...authComputed,
    },
    methods: {
        ...authMethods,
        calculatePrice(a, b) {
            return parseInt(a) + parseInt(b);
        },
    }
}
</script>

<style>

</style>