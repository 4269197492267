// const loDash = require('lodash')

export const caratshome = {
  state: () => ({
    iEMobileFirst: 0,
    iEOptimized: 1,
    iESecondaryImages: 1,
    iEVideo: 2,
    iEBulletPoints: 2,
    iEProductDesc : 0,
    iERichContent : 2,
    iEProductVariation : 0,
    iEBrandMenu : 0,
    iEEfficient : 0,
    iEHighlight : 1,
    iERetailer : 1,
    iERate : 0,
    iELightBox : 0,
    iEFrequentlyBought : 1,
    iEPremium : 1,
    iEVirtual : 1,
    iECheckout : 1,
    iESponsoredProduct : 0,
    iERescentlyPurchase : 1,
    iEBanner : 1,
    iERecords : 1,
    iEVideoSearch: 1,
    rdBanner:"",
    productVariationStyle:"",
    rdRate:"",
    rdSecondaryImages:""
  }),
  mutations: {
    SET_CARATS_HOME(state, payload) {
      state[payload.forKey] = payload.value;
    },
  },
  actions: {
    setCaratsHome({ commit }, value) {
      commit("SET_CARATS_HOME", value);
    },
  },
  getters: {},
};
