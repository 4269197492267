<template>
  <span
    class="hotspot"
    :class="[classValue]"
    v-if="$store.state.carats.iEHotspot"
    v-on:click="setParticularInsights(keyValue)"
    :style="{ background: getBGColor() }"
  >
    <span class="hotspot-spot" :style="{ background: getColor() }"></span>
  </span>
</template>

<script>
import { authMethods } from "../store/helpers";
import { find } from "lodash";
export default {
  name: 'HotSpot',
  data() {
    return {
      colorS: "",
    };
  },
  props: {
    keyValue: {
      type: String,
      default: "",
    },
    classValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...authMethods,
    getColor() {
      var a = find(this.$store.state.insights.insightsAll, {
        section: this.keyValue,
      });
      if (a) {
        return a.class;
      }
      return "#41B878";
    },
    getBGColor() {
      var a = find(this.$store.state.insights.insightsAll, {
        section: this.keyValue,
      });
      if (a) {
        if (this.isHexColor(a.class)) {
          return this.hexToRgbA(a.class);
        } else {
          var d = document.createElement("div");
          d.style.color = a.class;
          document.body.appendChild(d)
          var b = window.getComputedStyle(d).color;
          document.body.removeChild(d)
          return 'rgba(' + b.split("(")[1].split(")")[0] + ', 0.2)'
        }
      }
      return "#B6E4CB";
    },
    isHexColor(hex) {
      return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    },
    hexToRgbA(hex) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          ",0.3)"
        );
      }
      return " #B6E4CB";
    },
  },
};
</script>

<style></style>
